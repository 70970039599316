.wizard-container {
    flex: 1;
    display: flex;
    align-items: center;
}

.step-container {
    @extend .egg-card;
    display: flex;
    overflow-y: hidden;
    margin: 0px 10px;
    padding: 0px;
    @media screen and (min-width: 768px) {
        min-width: 100%;
        max-height: 520px;
        margin: 0px auto;
    }
    .onboarding-img {
        display: none;
        background-color: $eggColor;
        flex-basis: 30%;
        background-image: url('../../assets/images/onboarding-img.png');
        &.step1 {
            background-image: url('../../assets/images/img_step1.png');
        }
        &.step2 {
            background-image: url('../../assets/images/img_step2.png');
        }
        &.step4 {
            background-image: url('../../assets/images/img_step4.png');
        }
        &.share {
            background-image: url('../../assets/images/img_share.png');
        }
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-top-left-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
        @media screen and (min-width: 768px) {
            display: block;
            border-top-left-radius: $border-radius-md;
            border-bottom-left-radius: $border-radius-md;
            border-top-right-radius: 0px;
        }
    }
    .onboarding-form {
        min-height: 520px;
        background: white;
        padding: 20px;
        flex-basis: 70%;
        display: flex;
        flex-direction: column;
        align-self: center;
        border-bottom-left-radius: $border-radius-md;
        border-bottom-right-radius: $border-radius-md;
        @media screen and (min-width: 768px) {
            padding: 40px 80px;
            border-top-right-radius: $border-radius-md;
            border-bottom-right-radius: $border-radius-md;
        }
        .info {
            margin: 0 10px;
            .step-label {
                font-size: 1.2rem;
                font-weight: bold;
                letter-spacing: 0.1rem;
                display: block;
            }
            .step-title {
                display: block;
                color: $darkGreyEgg;
                font-weight: bold;
                letter-spacing: -0.05rem;
                font-size: 2.5rem;
                margin-top: 5px;
                @media screen and (min-width: 768px) {
                    margin-top: 0px;
                    font-size: 3.4rem;
                }
            }
        }
        form {
            @media screen and (min-width: 768px) {
                max-height: 210px;
            }
            padding: 10px;
        }
        .recomendation-container {
            .recomendations-list {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                margin-top: -10px;
                overflow-y: scroll;
                @media screen and (min-width: 768px) {
                    max-height: 210px;
                }
                .list-item {
                    cursor: pointer;
                }
            }
        }
        .btn-next-wizard {
            @extend .btn-yellow;
            margin: 20px auto 0 auto;
            border-radius: $border-radius-sm;
            width: calc(100% - 20px);
            @media screen and (min-width: 768px) {
                margin: auto 10px 0 auto;
                width: 200px;
            }
        }
    }
    .loading-span {
        color: $eggColor;
        margin-left: -35px;
    }
}
