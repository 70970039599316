.bottomNotification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    min-width: 437px;
    height: 70px;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    z-index: 600002;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.success {
    background: #15c1b0 0% 0% no-repeat padding-box;
}

.error {
    background: #ff647c 0% 0% no-repeat padding-box;
}

.bottomNotificationIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #ffffff;
    height: 70%;
    width: 70;
}

.closeSection {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #ffffff;
    height: 70%;
    width: 70px;
    color: #fff;
    font-size: 24px;
    margin-left: 1em;
}
