.login-pages {
    min-height: 100vh;
    background: $lightGreyBg;
    display: flex;
    justify-content: center;
    align-items: center;
    .lang-switch {
        position: absolute;
        top: 30px;
        right: 30px;
        button {
            display: flex;
            align-items: center;
            background: none;
            border: none;
            cursor: pointer;
            .icon {
                font-size: 1.5rem;
            }
            span {
                font-weight: bold;
                margin-left: 5px;
            }
        }
    }
    .container {
        width: 90%;
        @media screen and (min-width: 768px) {
            width: 85%;
        }
        @media screen and (min-width: 1180px) {
            width: 80ch;
        }
        .info {
            margin: 0 10px;
            .step-label {
                font-size: 1.2rem;
                font-weight: bold;
                letter-spacing: 0.1rem;
                display: block;
            }
            .step-title {
                display: block;
                color: $darkGreyEgg;
                font-weight: bold;
                letter-spacing: -0.05rem;
                font-size: 2.5rem;
                margin-top: 5px;
                @media screen and (min-width: 768px) {
                    margin-top: 0px;
                    font-size: 3.4rem;
                }
            }
        }
        .onboarding-card {
            border: none;
            @extend .egg-card;
            display: flex;
            justify-content: center;
            margin: 30px 10px;
            padding: 0px;
            @media screen and (min-width: 768px) {
                min-width: 100%;
                margin: 0px auto;
            }
            .onboarding-img {
                display: none;
                background: $eggColor;
                flex-basis: 30%;
                background: url('../../assets/images/img_login.png');
                background-color: $eggColor;
                &.verify {
                    background: url('../../assets/images/img_verify.png');
                }
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                @media screen and (min-width: 768px) {
                    display: block;
                    border-top-left-radius: $border-radius-md;
                    border-bottom-left-radius: $border-radius-md;
                    border-top-right-radius: 0px;
                }
            }
            .onboarding-form {
                min-height: 520px;
                background: white;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: $border-radius-md;
                width: 100%;
                text-align: center;
                flex-basis: 100%;
                @media screen and (min-width: 768px) {
                    padding: 40px 80px;
                    border-top-right-radius: $border-radius-md;
                    border-bottom-right-radius: $border-radius-md;
                }
                form {
                    input {
                        margin: 7px 0;
                        overflow: visible;
                        font-size: 1.4rem;
                        line-height: 1.4rem;
                    }
                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                        transition: background-color 5000s ease-in-out 0s;
                        overflow: visible;
                    }
                    button {
                        margin-top: 10px;
                        width: 100%;
                        @media screen and (min-width: 768px) {
                            min-width: 200px;
                        }
                    }
                }
                .alt-links {
                    @media screen and (min-width: 768px) {
                        display: flex;
                        justify-content: center;
                        .forgot-psw-link {
                            margin: 0 10px;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    .version {
        position: fixed;
        bottom: 10px;
        right: 10px;
    }
}

.alt-links {
    padding-top: 10px;
    .forgot-psw-link {
        display: block;
        padding: 10px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.8rem;
        text-decoration: underline;
        background-color: transparent;
        border: none;
        color: $darkGreyEgg;
    }
}
