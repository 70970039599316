.section {
    padding: 1rem;
}

.section header {
    display: flex;
    gap: 1rem;
    font-size: 1.1875rem;
    justify-content: space-between;
}

.section hr {
    margin: 2rem 0;
}
.section main {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 1rem;
}

.title {
    font-size: 1.1875rem;
    font-weight: 700;
    flex-grow: 2;
    text-align: center;
}

.time {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: column;
}

.time input,
.time select {
    padding: 0.5rem;
}

.time div {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.time .endDate {
    text-transform: capitalize;
    color: #003750;
    font-size: 0.88rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.time small {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    color: #121111;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.time .teamsMeetingEndDate {
    width: 100%;
    height: 2.625rem;
    text-align: center;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    background: #fff;
}

.amount {
    padding: 0.5rem;
    width: 9ch;
}

.unit {
    flex-grow: 2;
    text-align: center;
}

.keepsTeam {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.keepsTeam > input {
    transform: scale(1.5);
    margin: 1rem;
    margin-left: 0;
    padding: 0.5rem;
}

.activeMeeting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 6rem;
    border-radius: 0.75rem;
    background: #e7e9ea;
    gap: 5px;
}

.activeMeeting .countdown {
    width: 4rem;
    height: 1.5rem;
    text-align: center;
}

.activeMeeting p {
    font-weight: 500;
    color: #003750;
    text-align: center;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.confirm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
}

.confirm .top {
    display: grid;
    gap: 1rem;
    place-items: center;
    margin-top: 6rem;
}

.confirm .top svg {
    font-size: 2.5rem;
}

.confirm .top .text {
    width: 14.275rem;
    color: #003750;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;
}

.confirm .bottom {
    display: grid;
    gap: 1rem;
    place-items: center;
    width: 100%;
}

.confirm .bottom button {
    width: 100%;
}
