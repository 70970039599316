.team-detail-student-container {
    .header {
        background: $eggColor;
        background-size: 140%;
        background-position: -50% 50%;
        background-repeat: no-repeat;
        border-radius: $border-radius-md;
        padding: 40px;
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
            background: $eggColor url('../../../assets/images/redes.png');
            padding: 80px;
            background-size: 50%;
            background-position: 0% 500%;
            background-repeat: no-repeat;
        }
        display: flex;
        .data {
            flex-basis: 50%;
            // background: red;
            .hq-name {
                font-size: 1.8rem;
                @media screen and (min-width: 768px) {
                    font-size: 2.28rem;
                }
                font-weight: bold;
                color: white;
            }
            h1 {
                margin: 0;
                font-size: 3rem;
                @media screen and (min-width: 768px) {
                    font-size: 5rem;
                }
            }

            .hq-name {
                font-size: 1.8rem;
                @media screen and (min-width: 768px) {
                    font-size: 2.28rem;
                }
                font-weight: bold;
                color: white;
            }
            .team {
                width: 100%;
                @media screen and (min-width: 768px) {
                    width: 60%;
                }
                h1 {
                    margin: 0;
                    margin-top: 10px;
                    font-size: 3rem;
                    @media screen and (min-width: 768px) {
                        font-size: 5rem;
                        line-height: 5rem;
                    }
                }
            }
            .goal {
                margin-top: 14px;
                font-size: 1.2rem;
                margin-bottom: 30px;
                width: 100%;
                @media screen and (min-width: 768px) {
                    width: 60%;
                }
            }

            .actions-block {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }
                .action-link {
                    margin-left: -15px;
                    margin-bottom: 10px;
                    .icon {
                        width: 25px;
                    }
                    @media screen and (min-width: 768px) {
                        margin-left: 0px;
                    }
                    &:first-child {
                        @media screen and (min-width: 768px) {
                            // margin-bottom: 0px;
                            margin-left: -15px;
                        }
                    }
                }
            }
        }
        .table {
            flex-basis: 50%;
            width: 100%;
            text-align: center;
            margin-top: 20px;
            @media screen and (min-width: 768px) {
                margin-top: 0px;
                display: flex;
                justify-content: flex-end;
            }
            .egg-card {
                width: 100%;
                @media screen and (min-width: 768px) {
                    width: 80%;
                }
                .table-number {
                    font-weight: bold;
                    font-size: 2rem;
                    text-align: center;
                    display: block;
                    // margin-bottom: 15px;
                }
                .empty-icon {
                    color: $eggColor;
                    font-size: 4rem;
                    margin-bottom: 20px;
                }
                .table-empty-msg {
                    padding: 20px;
                    span {
                        display: block;
                    }
                    .first-line {
                        font-size: 1.4rem;
                        font-weight: bold !important;
                    }
                    .second-line {
                        font-size: 1.4rem;
                        line-height: 1.8rem;
                        margin-top: 5px;
                    }
                }
            }
            // background: #000;
        }
    }

    .messages-block {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
    }

    // .tables-block-container{
    // 	.tables-block{
    // 		@include egg_general_block;
    // 	}

    // 	.is-placeholder{
    // 		opacity: .25;
    // 		pointer-events: none;
    // 		visibility: hidden;
    // 		@media screen and (min-width:768px){ visibility: visible; }
    // 	}
    // }

    .facilitadores-block {
        margin: 20px 0;
        padding: 60px 10px;
        border-radius: $border-radius-md;
        background-color: $darkGreyEgg;
        color: white !important;
        @media screen and (min-width: 768px) {
            padding: 60px;
        }
        &.placeholder {
            opacity: 0.3;
        }
        .heading {
            h3 {
                text-align: center;
            }
            @media screen and (min-width: 768px) {
                h3 {
                    text-align: left;
                }
            }
        }
        .content {
            padding: 40px 0 0;
            .facilitador-item {
                display: flex;
                text-align: center;
                flex-direction: column;
                &.is-placeholder {
                    // background-color: red;
                    opacity: 0.1;
                    pointer-events: none;
                    visibility: hidden;
                    @media screen and (min-width: 768px) {
                        visibility: visible;
                    }
                }
                .avatar-egg {
                    margin: 0 auto;
                }
                .name-label {
                    font-size: 1.2rem;
                    margin-top: 15px;
                    color: white;
                }
                .name-table {
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 0.01rem;
                    margin-top: 4px;
                    color: white;
                }
            }
        }
    }
}

.content-no-assignation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    @media screen and (min-width: 768px) {
        padding: 120px 0;
    }
    .icon {
        font-size: 4rem;
    }
}

.modal-jitsi {
    width: 90% !important;
    height: 90% !important;
    display: flex;
    flex-direction: column;
    padding: 10px;
    .iframe-modal-jitsi {
        border-radius: 10px;
        border: none;
    }
    button {
        margin-top: 10px;
        border: 1px solid $separadorLineas;
        border-radius: 4px;
        background: none;
        padding: 10px 20px;
    }
}
