.container {
    display: inline-block;
    width: fit-content;
    padding: 1em;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    color: #37474f;
    margin: 3em 0 1em 0;
}

.datePicker,
.btnDownload {
    margin-left: 1em;
}

.title {
    font-size: 14px;
    font-weight: bold;
}

.datePicker {
    color: inherit;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    height: 42px;
    padding: 0 1em;
    outline: 0;
}
.btnDownload {
    cursor: pointer;
    font-weight: bold;
    padding: 10px;
    color: white;
    background: #37474f 0% 0% no-repeat padding-box;
    border: 0;
    outline: 0;
    border-radius: 5px;
    height: 42px;
    padding: 0 20px;
}
.btnDownload > svg {
    margin-left: 10px;
}
