.card {
    position: relative;
    background: var(--monitor-card-background);
    padding: 2em;
    display: grid;
    align-content: stretch;
    border-radius: var(--monitor-card-rounded);
    -webkit-border-radius: var(--monitor-card-rounded);
    -moz-border-radius: var(--monitor-card-rounded);
    -ms-border-radius: var(--monitor-card-rounded);
    -o-border-radius: var(--monitor-card-rounded);
    font-weight: 500;
}
.itemsContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.item {
    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: center;
}
.item:not(:last-child) {
    border-bottom: 1px solid var(--lightGrey);
    padding-bottom: 1em;
}
.col1 {
    display: flex;
    gap: 1em;
    align-items: center;
}
.col1 > p {
    font-size: 1rem;
}
.icon {
    width: 21px;
    height: 22px;
}
.value {
    font-size: 1.429rem;
    font-weight: 700;
}
