.pagination {
    margin: 30px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
        width: 50%;
    }

    & button {
        min-width: unset;
    }

    .nav-btn {
        margin: 4px;
        font-size: 1.2rem;
        button {
            cursor: pointer;
            color: darken($mediumGreyBg, 10%);
            background: none;
            border: none;
            .icon {
                font-size: 1.5rem;
            }
            &:hover {
                transition: all 0.3s ease-in-out;
                color: darken($mediumGreyBg, 50%);
            }
            &.active {
                color: $darkGreyEgg;
            }
            &:disabled {
                opacity: 0.3;
                pointer-events: none;
            }
        }
    }
    & > .middle-page-btn {
        display: flex;
    }
}
