.egg-card {
    @include sombra;
    background: white;
    border-radius: $border-radius-md;
    padding: 20px;
    border: 1px solid $borders;

    &:hover {
        background-color: #f8f8f8;
    }
}

.egg-list {
    .list-item {
        padding: 30px 20px;
        border-bottom: 1px solid $lightGreyBg;
        &:last-child {
            border-bottom: none;
        }
        .clickeable-item {
            background: red;
        }
    }
}

.clickeable-item {
    &:hover {
        background: darken(white, 5%);
        border-radius: $border-radius-md;
    }
}

.egg-triangle-bottom-centered::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 0;
    width: 0;
    border-top: 25px solid white;
    border-right: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 25px solid transparent;
    transform: translate(0, 100%);
}

@mixin egg-general-block {
    margin: 20px 0;
    padding: 60px 10px;
    @media screen and (min-width: 768px) {
        padding: 60px;
    }
    .heading {
        display: flex;
        flex-direction: column;
        // align-items:center;
        justify-content: space-between;
        margin-bottom: 20px;
        h3 {
            text-align: center;
            margin-bottom: 20px;
        }
        @media screen and (min-width: 768px) {
            flex-direction: row;
            h3 {
                text-align: center;
                margin-bottom: 0px;
            }
        }
        .block-actions {
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-content: center;
            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
            // #delete-btn{
            // 	margin-right: -30px;
            // }
            a {
                display: flex;
                align-items: center;
                flex: 1;
                flex-basis: 0;
                justify-content: center;
                align-items: flex-end;
                @media screen and (min-width: 768px) {
                    display: inline;
                    flex: auto;
                    flex-basis: auto;
                }
                .action-link {
                    display: flex;
                    flex-direction: column;
                    margin: 0 10px !important;
                    text-align: center;
                    width: 100%;
                    .icon {
                        font-size: 3rem;
                        margin-bottom: 5px;
                        @media screen and (min-width: 768px) {
                            font-size: 1.5rem;
                            margin-bottom: 0px;
                        }
                    }
                    span {
                        // display: none;
                        word-wrap: break-word;
                        font-size: 0.7rem;
                        min-height: 25px;
                        @media screen and (min-width: 768px) {
                            display: block;
                            font-size: 1.1rem;
                            min-height: auto;
                        }
                    }
                    @media screen and (min-width: 768px) {
                        flex-direction: row;
                    }
                    button {
                        background: none;
                        border: none;
                    }
                }
            }
            #add-btn,
            #delete-btn {
                display: none;
                width: 30px;
                @media screen and (min-width: 768px) {
                    display: block;
                }
                .action-link {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
    .content {
        display: flex;
        flex-flow: column nowrap;
        @media screen and (min-width: 768px) {
            flex-flow: row;
        }
        &.slider {
            display: block;
        }
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-basis: 33%;
            margin: 5px;
            @media screen and (min-width: 768px) {
                margin: 10px;
            }
            &.slider-item {
                // background-color: green;
                // box-shadow: none;
                border: 1px solid $borders;
                margin: 0px;
                @media screen and (min-width: 768px) {
                    margin: 5px;
                }
                .name-table {
                    font-weight: bold;
                    // text-transform: uppercase;
                    font-size: 1.25rem;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

// SLIDER

.slick-slide {
    padding: 3px;
    div {
    }
}
