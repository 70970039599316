.signup-form {
    // text-align: left;
    // padding:40px;
    @media screen and (min-width: 768px) {
        width: 60%;
    }
    .responsive {
        @media screen and (min-width: 768px) {
            display: flex;
        }
        div {
            width: 100%;
            margin: 0 5px;
        }
    }
    .action-buttons {
        // display:flex;
        flex-direction: row-reverse;
        flex-direction: column !important;
        @media screen and (min-width: 768px) {
            flex-direction: row !important;
        }
        button {
            &:disabled {
                opacity: 0.3;
                pointer-events: none;
            }
        }
    }
}
.container-buttons-signup {
    @media (min-width: 800px) {
        width: 98%;
    }
}
@media (max-width: 800px) {
    i {
        font-size: 25px;
        position: relative;
        top: -65px;
        left: 40%;
        z-index: 20;
        color: rgb(56, 56, 56);
    }
}

@media (min-width: 800px) {
    i {
        font-size: 25px;
        position: relative;
        top: -50%;
        left: 40%;
        z-index: 20;
        color: rgb(56, 56, 56);
    }

    i:hover {
        color: rgb(255, 205, 0);
        cursor: pointer;
    }
}
