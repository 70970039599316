.studentItem {
    display: grid;
    grid-template-columns: 40% 50% auto;
    align-items: center;
    gap: 2rem;
    padding: 0 40px 0 20px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-top-color: #ffffff;
}

.studentInfo {
    display: flex;
    align-items: center;
    padding: 30px 0;
    flex-basis: 100%;
}

.position {
    font-weight: bold;
    font-size: 1.4rem;
    padding: 0 20px 0 30px;
}

.avatar {
    position: relative;
    padding: 0 5px 0 20px;
    cursor: pointer;
}

.avatarBadges {
    color: white;
    font-size: 15px;
    position: absolute;
    bottom: 0px;
    left: 79%;
    display: flex;
    align-items: center;
}

.avatarBadges > button {
    margin-left: -12px;
}

.label {
    font-size: 17px;
    color: rgb(55, 71, 79);
    text-transform: capitalize;
    margin-left: 25px;
    cursor: pointer;
}

.assignedBadge {
    border-radius: 4px;
    padding: 5px;
    margin: 3px 10px 0 0;
    display: inline-block;
    margin-left: 10px;
    font-size: 0.8rem;
    background-color: #ffcd00;
}

.unAssignedBadge {
    border-radius: 4px;
    padding: 5px;
    margin: 3px 10px 0 0;
    display: inline-block;
    margin-left: 10px;
    font-size: 0.8rem;
    background-color: #ee8686;
}

.mentorLabel {
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 4px;
}
.peopleResults:first-child {
    border: 1px solid inherit;
    border-top-color: #f2f2f2;
    border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    -ms-border-radius: 10px 10px 0 0;
    -o-border-radius: 10px 10px 0 0;
}

.actionsBlock {
    margin-left: auto;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: 10px 0 10px;
    display: grid;
    grid-template-columns: 1fr max-content;
    justify-items: end;
}
@media screen and (min-width: 768px) {
    .actionsBlock {
        flex-basis: 50%;
    }
}

.talkingQuality {
    width: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 1rem;
}

.talkingQuality > p {
    margin: 0;
    text-align: center;
    font-weight: bold;
}

.talkingQualityTitle {
    font-weight: bold;
    text-align: center;
}

.talkingQualityIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
