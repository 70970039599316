.dashboard {
    .header {
        border-radius: 10px;
        background: $lightGreyBg;
        background-image: none;
        padding: 50px 40px;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
.search-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    .report-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: flex-end;
        margin-bottom: 20px;
        margin-left: 40px;
        cursor: pointer;

        width: 350px;
        text-align: left;
        .span-style {
            margin-left: 5px;
            font-weight: bold;
            font-size: 15px;
            letter-spacing: 0.65px;
            color: #37474f;
            opacity: 1;
            text-transform: uppercase;
        }

        .icon-style {
            /* UI Properties */
            text-align: left;
            font-size: 25px;
            letter-spacing: 0px;
            color: #37474f;
            opacity: 1;
        }
    }
}
.teams-dashboard-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    h2 {
        margin-bottom: 15px;
    }

    .items-grid {
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
        gap: 10px;
        width: 100%;

        .add-item-btn {
            cursor: pointer;
            background: $lightGreyBg;
            border-radius: $border-radius-md;
            min-height: 202px;
            max-height: 205px;
            flex-basis: 100%;
            @media screen and (min-width: 581px) {
                flex-basis: calc(33.333% - 20px);
                margin: 10px;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 20px;
            .add-icon {
                font-size: 3rem;
            }
            transition: all 0.2s ease-in-out;
            &:hover {
                opacity: 0.5;
            }
        }
        .team-item-block {
            margin: 10px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 202px;
            padding: 20px 40px;
            position: relative;
            box-sizing: border-box;
            // word-break: break-all;
            overflow: hidden;
            .first-time-user {
                position: absolute;
                left: 0;
                top: -104px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: $darkGreyEgg;
                border-radius: 10px;
                padding: 10px 20px;
                width: 200px;
                height: 85px;
                color: white;
                text-align: center;
                &:after {
                    position: absolute;
                    top: 85px;
                    right: 85px;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 15px 15px 0px 15px;
                    border-color: $darkGreyEgg transparent transparent transparent;
                }
                span:first-child {
                    color: $eggColor;
                    font-weight: bold;
                }
            }
            &.show-obj {
                overflow: visible;
            }
            &:hover {
                cursor: pointer;
                background-color: darken(white, 2%);
            }
            @media screen and (min-width: 581px) {
                flex-basis: calc(33.333% - 20px);
                margin: 10px;
                max-height: 205px;
            }
            .obj-btn {
                position: absolute;
                top: 15px;
                right: 15px;
                font-size: 1.8rem;
                &:hover {
                    color: $eggColor;
                }
                &.desk {
                    display: none;
                    @media screen and (min-width: 768px) {
                        display: block;
                    }
                }
                &.mobile {
                    display: block;
                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                }
            }
            .team-info {
                // background-color: lightsalmon;
                padding: 40px 0;
                margin-right: 0px;
            }
            .curso {
                display: block;
                font-size: 2.1rem;
                font-weight: bold;
                letter-spacing: -0.08rem;
                margin-bottom: 5px;
            }
            .hq {
                display: block;
                font-size: 1.4rem;
                font-weight: lighter;
                margin-bottom: 10px;
            }
        }
    }
}
