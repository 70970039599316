.details {
    min-width: 550px;
    min-height: 582px;
}

.details > header {
    display: flex;
    gap: 1em;
    background-color: var(--darkGreyEgg);
    padding: 10px 20px;
    border-radius: var(--radius) var(--radius) 0 0;
}

.details > header > .videoIcon {
    font-size: 25px;
    color: white;
    background: #00aaff;
    padding: 15px;
    border-radius: 50px;
}
.details > header .title {
    margin: auto 0;
}
.details > header .title > * {
    letter-spacing: 0.2px;
}

.details > header h2 {
    color: white;
    font-size: 22px;
}

.details > header h4 {
    margin: 0;
    font-size: 15px;
    color: #bababa;
}

.details > header .btnClose {
    background: 0;
    min-width: unset;
    border: 0;
    font-size: 29px;
    color: white;
    padding: 0;
    margin-left: auto;
}

.details .list {
    margin: 1em 1em 0 1em;
    overflow: auto;
    max-height: 562px;
    padding-right: 20px;
    scrollbar-color: var(--darkGreyEgg) #eaeaea;
}

.details .list::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    padding-left: 5px;
    background-color: #eaeaea;
}

.details .list::-webkit-scrollbar-thumb {
    background: var(--darkGreyEgg);
    border-radius: 4px;
}

.details .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #dedede;
}

.details .item:nth-last-child(-n + 2) {
    border: 0;
}

.details .item.hight .icon {
    color: var(--color-success);
}
.details .item.medium .icon {
    color: var(--color-exclamation);
}
.details .item.low .icon {
    color: var(--color-danger);
}

.details .item .team {
    font-weight: 600;
}

.details .item .description {
    font-weight: normal;
}
.details .item .count {
    font-weight: 600;
}
.details .item .meet {
    width: 96px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #0000000d;
    border: 1px solid #eaeaea;
    border-radius: var(--radius);
    font-size: 17px;
    color: inherit;
}
