.share-container {
    .share-options {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: space-around;
        }
        .share-option {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 2rem 0 1rem 0;
            position: relative;
            padding: 10px 20px;
            border-radius: $border-radius-md;
            text-align: center;
            letter-spacing: 0.1rem;
            &:hover {
                background: darken(white, 5%);
            }
            .share-option-icon {
                font-size: 2.5rem;
                color: $eggColor;
            }
            .share-option-label {
                margin-top: 0.75rem;
                word-wrap: normal;
                width: 6.7rem;
                font-size: 1rem;
                text-transform: uppercase;
                color: $darkGreyEgg;
                opacity: 0.5;
            }
        }
        .share-option:hover {
            cursor: pointer;
        }
    }
    .input-container {
        margin-top: 15px;
        position: relative;
        #share-url {
            font-size: 1.8rem;
            color: $darkGreyEgg;
            text-align: center;
            font-weight: 300;
        }
    }
    .next-step {
        .btn-next-wizard {
            float: right;
        }
    }
}

.tool-tip {
    width: 100px;
    position: absolute;
    padding: 0.75rem 0;
    top: -50px;
    right: 0px;
    margin: auto;
    background-color: $darkGreyEgg;
    color: white;
    border-radius: 0px 10px 10px 10px;
    opacity: 0;
    transition: opacity 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    pointer-events: none;
    @media (max-width: 600px) {
        border-radius: 10px 10px 10px 10px;
        top: -10px;
        right: 0;
        left: 0;
        margin: auto;
        transform: translate(0%, -100%);
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin: auto;
        height: 0;
        width: 0;
        border-top: 5px solid $darkGreyEgg;
        border-right: 5px solid $darkGreyEgg;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
        transform: translate(-100%, 0%);
        @media (max-width: 600px) {
            top: unset;
            bottom: 0;
            right: 0;
            margin: auto;
            border-top: 10px solid $darkGreyEgg;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid transparent;
            transform: translate(0%, 100%);
        }
    }
}

.qr-code {
    height: 100%;
    width: 100%;
}
