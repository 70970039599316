.wizard-overlay {
    background-color: rgba(55, 71, 79, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.wizard-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 800px;
    background-color: white;
    border-radius: 15px;
    margin: 10px;
    max-height: 100vh;
    overflow-y: auto;
    @media screen and (min-width: 768px) {
        margin: auto;
        max-height: 620px;
    }
    &:focus {
        outline: none;
    }
    .heading {
        padding: 60px 40px;
        background-color: $lightGreyBg;
        @media screen and (min-width: 768px) {
            background-image: url(../../assets/images/header_nuevocurso_desktop.jpg);
            background-repeat: no-repeat;
            background-position: 90% 50%;
            background-size: cover;
        }
        text-align: left;
        justify-content: flex-start;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        position: relative;
        &.nohqs {
            background-image: url(../../assets/images/header_nosede_desktop.jpg);
            background-position: 85% 50%;
            // background-size:50%;
            .padded {
                width: 50%;
            }
        }
        .close-icon {
            position: absolute;
            top: 15px;
            right: 25px;
            font-size: 2rem;
            cursor: pointer;
            &:hover {
                color: $eggColor;
            }
        }
    }
    .content {
        padding: 20px;
        @media screen and (min-width: 768px) {
            padding: 20px 40px;
        }
        .form {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-evenly;
            align-items: stretch;
            .wizard-icon {
                font-size: 5rem;
                color: $eggColor;
                align-self: center;
            }
            h4 {
                font-size: 1.625;
                letter-spacing: 0.03rem;
                margin-top: 10px;
                margin-bottom: 30px;
            }
            .cols {
                display: flex;
                .col {
                    flex: 1 1 0;
                    .form-group {
                        overflow: hidden;
                        margin: 00px 0 10px;
                        display: flex;
                        flex-direction: column;
                        padding: 10px;
                        position: relative;
                        .form-input {
                            margin-top: 10px;
                            &:first-child {
                                margin-bottom: 0;
                            }
                        }
                        &.textarea {
                            height: 100%;
                        }
                        .counter {
                            position: absolute;
                            bottom: 30px;
                            right: 25px;
                            margin-top: 5px;
                            align-self: flex-end;
                            font-weight: bold;
                        }
                    }
                    &:last-child {
                        textarea {
                            flex: 1;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
            .action-buttons {
                display: flex;
                flex-direction: row;

                @media screen and (min-width: 768px) {
                    margin: 0 auto;
                    width: 80%;
                }
                // margin: 0 auto 0 auto;
                .cancel,
                .submit {
                    font-size: 1.3rem;
                    flex: 1 1 0;
                    margin: 10px;
                }
                .btn-outline {
                    padding: 15px 25px;
                }
            }
        }
    }
    .nohqs-modal {
        text-align: center;
        @media screen and (min-width: 768px) {
            width: 60%;
            margin: 0 auto;
        }
        .padded {
            padding: 40px;
            @media screen and (min-width: 768px) {
                width: 60%;
            }
        }
    }

    .nohqs-modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // height: 100%;
        // background-color: lightsalmon;
        margin-top: 100px;
        .wizard-icon {
            font-size: 5rem;
            color: $eggColor;
            align-self: center;
        }
        h4 {
            font-size: 2rem;
            letter-spacing: 0.02rem;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .contact-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.25rem;
            font-weight: 400;
            span {
                padding: 5px;
            }
            a {
                font-weight: 500;
                color: $darkGreyEgg;
            }
        }
    }
}
