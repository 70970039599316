.container {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.isFetching * {
    opacity: 0.7;
}

.spinner {
    text-align: center;
    padding-bottom: 5rem;
}

.team {
    background-color: var(--white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.teamsList {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.updateData {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.btnRefresh {
    align-items: center;
    color: #003750;
    background: #ffffff;
    border: 2px solid #003750;
    border-radius: 8px;
    display: flex;
    font-size: 15px;
    min-width: unset;
    padding: 10px 15px;
    position: relative;
}

.btnRefresh:hover {
    background-color: #eaeaea;
}

.btnRefreshText {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
}
