.student-detail-container {
    .personal-data {
        @include egg-general-block;
        padding-top: 80px;
        padding-bottom: 80px;
        border-radius: 15px;
        padding: 40px;
        @media screen and (min-width: 768px) {
            padding: 80px 60px;
        }
        span {
            display: block;
        }
        .title-edit {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .btn-edit-data {
            margin-left: 0;
        }
        .data-grid {
            margin-top: 20px;
            display: flex;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            div {
                flex-basis: 50%;
            }
            .data-item {
                margin: 40px 5px;
                &:first-child {
                    margin-top: 10px;
                }
                .label {
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: 0.1rem;
                }
                input.data {
                    font-size: 1.5rem;
                    font-weight: lighter;
                    margin-top: 8px;
                    width: 100%;
                    border-radius: 10px;
                    padding: 10px 20px;
                    border: 1px solid rgba(20, 20, 20, 0.2);
                    height: 60px;
                    &:disabled {
                        margin-top: -5px;
                        font-size: 1.7rem;
                        border: none;
                        background-color: transparent;
                        box-shadow: none;
                        padding-left: 0;
                    }
                }
            }
        }
    }
    .block-notes {
        @include egg-general-block;
        padding: 80px 0;
        .list-item {
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 768px) {
                flex-direction: row;
                align-items: center;
            }
            .icon-cont {
                order: 1;
                width: 4%;
                display: none;
                @media screen and (min-width: 768px) {
                    display: block;
                    padding-right: 20px;
                    order: 0;
                }
                .icon {
                    font-size: 1.5rem;
                    margin-right: 10px;
                }
                // float:left;
            }
            .text {
                // background-color: antiquewhite;
                order: 2;
                p {
                    margin: 0;
                }
                @media screen and (min-width: 768px) {
                    order: 1;
                    min-width: 67%;
                }
            }
            .date {
                padding: 0 10px;
                // background-color: aqua;
                width: 35%;
                order: 0;
                float: left;
                p {
                    color: darken(white, 20%);
                }
                @media screen and (min-width: 768px) {
                    order: 2;
                }
            }
        }
    }
}
