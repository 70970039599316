@import './components/MeetingConfigBlock/styles';

.team-settings-container {
    @include egg_general_block;
    margin: 0px !important;
    padding: 0px !important;
    .content {
        flex-direction: column;
        .team-info {
            width: 100%;
            padding: 10px 95px 40px;

            form {
                display: flex;
                flex-direction: column;
                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }
                .form-group {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 16px;
                    width: 100%;
                    @media screen and (min-width: 768px) {
                        margin: 10px;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    .input-group {
                        display: flex;
                        align-items: center;
                        // width: 100%;
                    }
                }
            }
        }

        .algorithm-config,
        .classroom-config {
            height: 100%;
            // padding:15px;
            .classroom-heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .block-title-classroom {
                    margin-bottom: 0px;
                }
            }
            h3 {
                margin-bottom: 40px;
            }

            .block-title {
                margin-bottom: 30px;
            }
            .block-desc {
                margin-bottom: 30px;
            }
            .service-switch-block {
                display: flex;
                flex-wrap: wrap;
                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }
                .service-item {
                    flex-basis: calc(50% - 20px);
                    @media screen and (min-width: 768px) {
                        flex: 1 1 0;
                        padding: 10px 20px;
                    }
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin: 10px;
                    opacity: 0.3;
                    border: 2px solid $mediumGreyBg;
                    border-radius: 15px;
                    min-height: 150px;
                    &.inactive {
                        box-shadow: none;
                    }
                    &.active {
                        opacity: 1;
                        border: 2px solid white;
                    }
                    cursor: pointer;
                    img {
                        width: 70px;
                        margin-bottom: 7px;
                    }
                    span {
                        font-size: 1.2rem;
                        font-weight: bold;
                        margin-top: 0.5rem;
                    }
                    @media screen and (min-width: 768px) {
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            .mode-switch-block {
                background-color: darken(white, 5%);
                padding: 10px;
                display: flex;
                flex-direction: column;
                border-radius: 15px;
                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }
                .mode-button {
                    cursor: pointer;
                    flex-basis: 50%;
                    border-radius: 15px;
                    border-radius: 15px;
                    text-align: center;
                    background-color: transparent;
                    padding: 20px 40px;
                    @media screen and (min-width: 768px) {
                        padding: 20px 80px;
                    }
                    .title {
                        font-size: 1.8rem;
                        font-weight: bold;
                    }
                    &.active {
                        @include sombra;
                        background-color: white;
                        .title {
                            color: $eggColor;
                        }
                    }
                }
            }
        }

        .advance-configuration {
            width: 100%;
            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                .block-title {
                    flex: 1;
                }
                margin-bottom: 32px;
            }
            .toggle-btn {
                border: none;
                background: none;
                font-size: 3rem;
                position: relative;
                cursor: pointer;
                &:disabled {
                    .box {
                        color: $mediumGreyBg !important;
                        cursor: default;
                    }
                }
                .box {
                    &.checked {
                        color: darken($eggColor, 0%);
                    }
                    &.unchecked {
                        color: darken($mediumGreyBg, 50%);
                    }
                }
                .spinner {
                    position: absolute;
                    right: 100%;
                    color: $darkGreyEgg;
                    font-size: 1.2rem;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
                }
            }
            .section {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 40px;
                margin-bottom: 20px;
                .description {
                    flex: 1;
                    .block-title {
                        margin-bottom: 16px;
                    }
                    min-width: 300px;
                }
                .actions {
                    display: flex;
                    flex-direction: row;
                    .prefix {
                        margin-right: 14px;
                    }
                    .suffix {
                        margin-left: 14px;
                    }
                    form {
                        display: flex;
                        align-items: center;
                    }
                    .form-input {
                        width: 220px;
                        padding: 10px 20px;
                    }
                    input {
                        border: none;
                        width: 80px;
                    }
                    .btn-yellow {
                        margin-left: 20px;
                        padding: 10px 20px;
                        width: 128px;
                    }
                }
            }
        }

        .algorithm-launch {
            display: flex;
            flex-direction: column;
            background-color: $darkGreyEgg;
            border-radius: 15px;
            padding: 60px 80px;
            margin: 20px 0;
            .top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                flex-direction: column;
                @media only screen and (min-width: 768px) {
                    flex-direction: row;
                }
                .info {
                    display: flex;
                    align-items: center;
                    .icon {
                        color: $eggColor;
                        font-size: 4rem;
                        @media only screen and (min-width: 768px) {
                            margin-right: 20px;
                        }
                    }
                    p {
                        margin: 10px 0 15px 0;
                        @media screen and (min-width: 768px) {
                            margin: 4px 0;
                        }
                    }
                }
                .btn-yellow {
                    min-width: 200px;
                    margin-top: 20px;
                    @media only screen and (min-width: 768px) {
                        margin-top: 0;
                    }
                }
            }
            .bottom {
                color: white;
                margin-bottom: 30px;
                @media only screen and (min-width: 768px) {
                    margin-bottom: 10px;
                }
                div {
                    display: flex;
                    flex-direction: column;
                    @media only screen and (min-width: 768px) {
                        flex-direction: row;
                        align-items: center;
                    }
                }
                button {
                    font-size: 2rem;
                    // color:red !important;
                    .icon {
                        &.unchecked {
                            color: white;
                        }
                    }
                }
                h5 {
                    margin-left: 10px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    text-align: center;
                    @media only screen and (min-width: 768px) {
                    }
                }
            }
        }

        .course-hours,
        .classroom-links {
            margin-top: 40px;

            .block-title {
                margin-bottom: 30px;
            }
            .block-desc {
                margin-bottom: 30px;
            }

            span.hours-config-label {
                font-weight: bold;
                margin-bottom: 10px;
                display: block;
                margin-bottom: 20px;
            }
            .classroom-links-list {
                height: 100%;
                padding: 15px;
                .tables-links-config-label {
                    font-weight: bold;
                    margin-top: 20px;
                    display: block;
                    margin-bottom: 5px;
                }
                .list-item {
                    padding: 10px 0 10px;
                    display: flex;
                    flex-direction: column;
                    @media screen and (min-width: 768px) {
                        flex-direction: row;
                    }
                    width: 100%;
                    .table-label {
                        font-size: 1.4rem;
                        color: $paragraphColor;
                        line-height: 1.8rem;
                        display: flex;
                        align-items: center;
                        flex-basis: 30%;
                    }
                    input {
                        padding: 10px;
                        background-color: darken(white, 5%);
                        border: none;
                        width: 100%;
                        text-align: center;
                        font-size: 1.4rem;
                        color: $paragraphColor;
                        border-radius: 5px;
                        margin: 5px 0px;
                        flex-basis: 70%;
                        &:disabled {
                            opacity: 0.5;
                        }
                        &::placeholder {
                            opacity: 0.7;
                        }
                        @media screen and (min-width: 768px) {
                            margin: 0 5px;
                        }
                    }
                }
            }
            .days-list {
                height: 100%;
                // padding:15px;
                .schedules-heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .block-title-classroom {
                        margin-bottom: 0px;
                    }
                }
                .list-item {
                    padding: 10px 0px;
                    display: flex;
                    flex-direction: column;
                    @media screen and (min-width: 768px) {
                        flex-direction: row;
                    }
                    .label-and-switch-container {
                        display: flex;
                        flex-basis: 40%;
                        margin-bottom: 10px;
                        @media screen and (min-width: 768px) {
                            margin-bottom: 0;
                        }
                        .day-label {
                            font-size: 1.4rem;
                            color: $paragraphColor;
                            line-height: 1.8rem;
                            display: flex;
                            align-items: center;
                        }
                        .day-switch {
                            margin-left: auto;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            cursor: pointer;
                            @media screen and (min-width: 768px) {
                                margin-right: 15px;
                            }
                        }
                    }
                    .time-input {
                        flex-basis: 30%;
                        span {
                            font-size: 0.7rem;
                            @media screen and (min-width: 768px) {
                                display: block;
                                margin-left: 5px;
                                margin-bottom: 5px;
                            }
                        }
                        input {
                            padding: 10px;
                            background-color: darken(white, 5%);
                            border: none;
                            width: 100%;
                            text-align: center;
                            font-size: 1.4rem;
                            color: $paragraphColor;
                            border-radius: 5px;
                            margin: 5px 0px;
                            &:disabled {
                                opacity: 0.5;
                            }
                            @media screen and (min-width: 768px) {
                                margin: 0 5px;
                            }
                        }
                        &.time-start {
                            // margin:0 5px;
                            @media screen and (min-width: 768px) {
                                margin: 0 5px;
                            }
                        }
                        &.time-end {
                            // margin:0 5px;
                            @media screen and (min-width: 768px) {
                                margin: 0 5px;
                            }
                        }
                    }
                }
            }
            .box {
                float: right;
                font-size: 2rem;
                &.unchecked {
                    color: $mediumGreyBg;
                }
                &.checked {
                    color: $eggColor;
                }
            }
        }

        .page-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 40px;
            .delete-team-action {
                color: white;
                opacity: 0.3;
            }
            .actions-container {
                button {
                    margin: 5px;
                    width: 100%;
                    @media screen and (min-width: 768px) {
                        width: auto;
                        margin: 10px;
                    }
                }
            }
        }
    }
    .sub-banner {
        align-items: center;
        background: #00a3ff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        margin: 30px 0 0;
        padding: 28px 36px 28px 28px;
        .course-info {
            align-items: center;
            display: flex;
            gap: 13px;
            .badge {
                background: #62c6ff;
                border-radius: 4px;
                box-shadow: 0px 0px 30px #0000000d;
                color: #ffffff;
                font-size: 10px;
                font-weight: bold;
                letter-spacing: 0.5px;
                padding: 6px 8px;
                text-align: left;
                text-transform: uppercase;
            }
            .title {
                color: var(---ffffff);
                font-size: 22px;
                font-weight: 800;
                text-align: left;
                &:first-letter {
                    text-transform: capitalize;
                }
            }
        }
        .disable-team {
            align-items: center;
            display: flex;
            gap: 15px;
            .disable-team-title {
                font-size: 13px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 16px;
                text-align: right;
                &:first-letter {
                    text-transform: capitalize;
                }
            }
        }
    }
    .settings-title {
        color: #003750;
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 0px;
        line-height: 24px;
        padding: 40px 0;
        text-align: left;
        &:first-letter {
            text-transform: capitalize;
        }
    }
    .settings-inside-title {
        color: #073045;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.65px;
        line-height: 16px;
        text-align: left;
        text-transform: uppercase;
    }
    .settings-inside-subtitle {
        color: #073045;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 22px;
        text-align: left;
    }
    .schedule-tolerance {
        display: flex;
        flex-direction: column;
        gap: 56px;
        padding: 60px 0 0;

        .tolerance-margin {
            display: flex;
            flex-direction: column;
            gap: 22px;

            .tolerance-margin-header {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
            .tolerance-margin-fields {
                display: flex;
                gap: 96px;
            }
        }
    }

    .tolerance-block-input {
        display: flex;
        flex-direction: column;
        gap: 16px;

        label {
            color: #073045;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: -0.18px;
            line-height: 19px;
            text-align: left;
        }
        .tolerance-input {
            align-items: center;
            display: flex;
            gap: 20px;
            input {
                background: #ffffff;
                border-radius: 10px;
                border: 1px solid #eaeaea;
                color: #1f394c;
                font-size: 17px;
                font-weight: 700;
                height: 40px;
                letter-spacing: 0px;
                line-height: 24px;
                text-align: center;
                width: 100px;
            }
            .tolerance-sufix {
                color: #073045;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: -0.16px;
                line-height: 16px;
                text-align: left;
                text-transform: capitalize;
            }
        }
    }

    .initial-table {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 0 95px 40px;

        .initial-table-title {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .title {
                color: #073045;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0.65px;
                line-height: 16px;
                text-align: left;
                text-transform: uppercase;
            }
            .subtitle {
                color: #606060;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 22px;
                text-align: left;
            }
        }
        .initial-table-input {
            align-items: center;
            display: flex;
            gap: 12px;
            label {
                color: #073045;
                font-size: 13px;
                font-weight: 600;
                letter-spacing: -0.16px;
                line-height: 16px;
                text-align: left;
            }
            input {
                background: #ffffff;
                border-radius: 10px;
                border: 1px solid #eaeaea;
                color: #1f394c;
                font-size: 17px;
                font-weight: 600;
                height: 40px;
                letter-spacing: 0px;
                line-height: 24px;
                text-align: center;
                width: 100px;
            }
        }
    }
}
