.profile-detail-container {
    max-width: 1120px;
    margin: 0 auto;
    .personal-data {
        @include egg-general-block;
        padding: 50px 40px;
        border-radius: 15px;
        background: white;
        @media screen and (min-width: 768px) {
            padding: 60px 80px;
        }

        span {
            display: block;
        }
        .title-edit {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .btn-edit-data {
            margin-left: 0;
        }
        .data-grid {
            display: flex;
            flex-direction: column;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            div {
                flex-basis: 50%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                @media (max-width: 768px) {
                    flex-direction: column;
                }
            }
            .data-item {
                margin: 20px 5px;
                margin-bottom: 10px;
                display: flex;
                height: 100px;
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                .label {
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: 0.1rem;
                }
                input.data {
                    font-size: 1.5rem;
                    font-weight: lighter;
                    margin-top: 8px;
                    width: 100%;
                    border-radius: 10px;
                    padding: 10px 20px;
                    margin-bottom: 5px;
                    border: 1px solid rgba(20, 20, 20, 0.2);
                    height: 60px;
                    &:disabled {
                        margin-top: -5px;
                        font-size: 1.7rem;
                        border: none;
                        background-color: transparent;
                        box-shadow: none;
                        padding-left: 0;
                    }
                }
                input.dataInput {
                    font-size: 1.5rem;
                    font-weight: lighter;
                    margin-top: 8px;
                    width: 100%;
                    border-radius: 10px;
                    padding: 10px 20px;
                    border: 1px solid rgba(20, 20, 20, 0.2);
                    height: 60px;
                    &:disabled {
                        font-size: 1.7rem;
                        background-color: transparent;
                        box-shadow: none;
                        margin-bottom: 5px;
                    }
                }
                select {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    font-weight: lighter !important;
                    margin-top: 8px !important;
                    width: 100%;
                    // padding:10px 20px;
                    margin-bottom: 5px;
                    font-size: 1.5rem;
                    border: 1px solid rgba(20, 20, 20, 0.2);
                    border-radius: 10px;
                    height: 60px;
                    padding-left: 20px;
                    &:disabled {
                        border: none !important;
                        font-size: 1.7rem !important;
                        // margin-top: -5px !important;
                        border: none !important;
                        background-color: transparent !important;
                        background: none !important;
                        box-shadow: none;
                        padding-left: 0;
                        // padding:10px 20px !important;
                        // margin-top: 8px !important;
                    }
                }
            }
        }
    }
    .block-notes {
        @include egg-general-block;
        padding: 80px 0;
        .list-item {
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 768px) {
                flex-direction: row;
                align-items: center;
            }
            .icon-cont {
                order: 1;
                width: 4%;
                display: none;
                @media screen and (min-width: 768px) {
                    display: block;
                    padding-right: 20px;
                    order: 0;
                }
                .icon {
                    font-size: 1.5rem;
                    margin-right: 10px;
                }
                // float:left;
            }
            .text {
                // background-color: antiquewhite;
                order: 2;
                p {
                    margin: 0;
                }
                @media screen and (min-width: 768px) {
                    order: 1;
                    min-width: 67%;
                }
            }
            .date {
                padding: 0 10px;
                // background-color: aqua;
                width: 35%;
                order: 0;
                float: left;
                p {
                    color: darken(white, 20%);
                }
                @media screen and (min-width: 768px) {
                    order: 2;
                }
            }
        }
    }
}
