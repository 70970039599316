.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    height: 50px;
    margin-bottom: 12px;
    gap: 1rem;
}

.infoText {
    font-size: 14px;
}

.buttons {
    display: flex;
    gap: 10px;
}

.buttons button {
    position: relative;
    min-width: auto;
    outline: 0;
    background: 0;
    border: 0;
    color: inherit;
    font-weight: 600;
    font-size: 12px;
    padding: 7px 10px;
    border-radius: var(--radius);
}

.buttons button > svg {
    margin-right: 5px;
}
.buttons button:hover {
    background-color: #f0f0f0;
}
