.header {
    flex-basis: 100%;
    text-align: left;
    height: fit-content;
}
.title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}
.subtitle {
    font-weight: 300;
    color: var(--monitor-card-text-light);
}
.cardInfo {
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
    font-size: 14px;
    line-height: 1.5;
    padding: 30px;
    border-radius: var(--monitor-card-rounded);
    background-color: #e7e7e794;
    backdrop-filter: blur(11px);
    text-align: center;
    z-index: 1;
}
.showInfo {
    all: unset;
    position: absolute;
    top: 5px;
    right: 5px;
}
