.user-action-attendance {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
        justify-content: flex-end;
    }
    .icons-block {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-end;
        font-size: 2rem;
        color: darken(white, 5%);
        padding: 0 10px;
        @media (min-width: 768px) {
            flex: 0;
            justify-content: flex-start;
            .slash {
                padding-left: 0px;
            }
        }
        button {
            background: none;
            width: 100%;
            display: flex;
            border: none;
            color: $mediumGreyBg;
            padding: 0 5px;
            cursor: pointer;
        }
        .icon {
            font-size: 2.3rem;
            padding: 2px 5px;
            transition: color 200ms ease;
        }
        .saving {
            margin: 0 3.5px;
        }
        .active .present,
        .present:hover:enabled,
        .present:focus:enabled {
            color: #7de780;
        }
        .active .delay,
        .delay:hover:enabled,
        .delay:focus:enabled {
            color: #66ccff;
        }
        .active .absent,
        .absent:hover:enabled,
        .absent:focus:enabled {
            color: #ee8686;
        }
    }
    .justify-block {
        display: flex;
        align-items: center;
        @media screen and (min-width: 768px) {
            margin-left: 20px;
        }
        .icon {
            font-size: 1.5rem;
            cursor: pointer;
        }
    }
}
