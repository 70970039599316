.mandatory-vote {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
        font-size: 3rem;
        line-height: 48px;
        text-align: left;
    }
    .desc {
        font-size: 1.4rem;
        line-height: 24px;
        width: 50%;
        text-align: center;
    }

    .vote-table {
        margin: 32px 0px;
        @include sombra;
        width: 55%;
        display: flex;
        background-color: rgba(255, 255, 255, 0.5);
        flex-direction: column;
        border-radius: 15px;
        .item {
            padding: 10px 40px;
            display: flex;
            position: relative;
            align-items: center;
            border-bottom: 1px solid $separadorLineas;
            flex-direction: column;
            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
            &:last-of-type {
                border-bottom: none;
            }
            .position {
                font-weight: 700;
                padding: 10px 10px 10px 10px;
                @media screen and (max-width: 768px) {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                }
            }
            .avatar-container {
                // height: 50px;
                // width: 50px;
                margin: 5px 10px;
            }
            .name {
                flex: 1;
                font-size: 1.1rem;
                margin: 10px;
            }
            .btn-reward {
                font-weight: 600;
                padding: 7px 15px;
                margin: 5px;
                border-radius: 40px;
                border: 2px solid $lightGreyBg;
                &.inactive {
                    background-color: transparent;
                }
                &.active {
                    .icon {
                        color: white;
                    }
                    background-color: $eggColor;
                }
                .icon {
                    width: 20px;
                    margin-right: 5px;
                }
            }
        }
    }

    .action-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        width: 60%;
        .submit-btn {
            @include sombra;
            cursor: pointer;
            text-transform: uppercase;
            padding: 24px;
            font-weight: 600;
            border: none;
            border-radius: 20px;
            width: 100%;
            // min-width: 300px;
            &.inactive {
                background-color: white;
            }
            &.active {
                background-color: $eggColor;
            }
            margin: 0px 30px;
            // flex: 1;
        }
    }
}
