.footer {
    background: $darkGreyEgg;
    width: 100%;
    .footer-container {
        max-width: 1170px;
        margin: 0 auto;
        padding: 30px 0px;
        color: white;
        box-sizing: border-box;
        // margin-top: 50px;
        display: flex;
        justify-content: flex-start;
        @media screen and (min-width: 768px) {
            justify-content: flex-end;
        }
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 20px;
        @media screen and (min-width: 768px) {
            justify-content: space-between;
        }
        .social-legal {
            display: flex;
            align-items: center;
            a {
                color: white;
            }
            text-align: center;
            @media screen and (min-width: 768px) {
                text-align: left;
            }
            .social-media-links,
            .web,
            .legal {
                margin: 5px 0;
                @media screen and (min-width: 768px) {
                    margin: auto auto;
                }
            }
            .social-media-links {
                a {
                    margin: 5px;
                    display: inline-block;
                    @media screen and (min-width: 768px) {
                        margin: 0 5px;
                    }
                }
                .icon {
                    color: $mediumGreyBg;
                    opacity: 0.3;
                    font-size: 1.8rem;
                }
                text-align: center;
                @media screen and (min-width: 768px) {
                    text-align: right;
                }
            }
            .web {
                margin: 0 30px;
                font-weight: bold;
            }
            .legal {
                text-transform: uppercase;
                font-size: 0.8rem;
            }
        }
        .logo {
            img {
                margin-top: 20px;
                height: 60px;
                @media screen and (min-width: 768px) {
                    margin-left: 10px;
                }
            }
        }
    }
}
