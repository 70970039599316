.students-table-view {
    width: 100%;
    height: 100%;
    position: relative;
    height: 250px;
    .central-logo {
        height: 50px;
        margin-top: -7px;
        &.inactive {
            filter: grayscale(100%);
        }
        &.active,
        &:hover {
            filter: grayscale(0);
        }
        &.disabled,
        &:hover {
            filter: grayscale(100%);
        }
    }
}
