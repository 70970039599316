.main-layout {
    //background: url('../assets/images/bg_degrade.png');
    background-color: #f8f8f8;
    background-repeat: repeat-x;
    background-position-y: 70px;
}

.full-width {
    width: 100% !important;
    max-width: 100%;
    padding: 0;
    .dashboard-grid-container {
        @media screen and (min-width: 768px) {
            max-width: 1117px;
            margin: 0 auto;
            width: 100%;
        }
    }
}
