.wizard-days-selector {
    max-height: 100%;
    overflow-y: scroll;
    @media screen and (min-width: 768px) {
        max-height: 250px;
    }
    .days-list {
        height: 100%;
        padding: 15px;
        .list-item {
            padding: 10px 0px;
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
            .label-and-switch-container {
                display: flex;
                flex-basis: 40%;
                margin-bottom: 10px;
                @media screen and (min-width: 768px) {
                    margin-bottom: 0;
                }
                .day-label {
                    font-size: 1.4rem;
                    color: $paragraphColor;
                    line-height: 1.8rem;
                    display: flex;
                    align-items: center;
                }
                .day-switch {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    cursor: pointer;
                    @media screen and (min-width: 768px) {
                        margin-right: 15px;
                    }
                }
            }
            .time-input {
                flex-basis: 30%;
                span {
                    font-size: 0.7rem;
                    @media screen and (min-width: 768px) {
                        display: block;
                        margin-left: 5px;
                        margin-bottom: 5px;
                    }
                }
                input {
                    padding: 10px;
                    background-color: darken(white, 5%);
                    border: none;
                    width: 100%;
                    text-align: center;
                    font-size: 1.4rem;
                    color: $paragraphColor;
                    border-radius: 5px;
                    margin: 5px 0px;
                    &:disabled {
                        opacity: 0.5;
                    }
                    @media screen and (min-width: 768px) {
                        margin: 0 5px;
                    }
                }
                &.time-start {
                    // margin:0 5px;
                    @media screen and (min-width: 768px) {
                        margin: 0 5px;
                    }
                }
                &.time-end {
                    // margin:0 5px;
                    @media screen and (min-width: 768px) {
                        margin: 0 5px;
                    }
                }
            }
        }
    }
    .box {
        float: right;
        font-size: 2rem;
        &.unchecked {
            color: $mediumGreyBg;
        }
        &.checked {
            color: $eggColor;
        }
    }
}
