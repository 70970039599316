.user-action-exam {
    width: 100%;
    margin: 0;
    @media screen and (min-width: 768px) {
        margin: 0 0px 0 20px;
    }
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (min-width: 768px) {
        width: 80%;
        margin-left: auto;
    }

    .input-score {
        @extend .form-input;
        min-width: 160px;
        max-width: 200px;
        padding: 20px;
        &:disabled {
            position: relative;
            //@at-root: $darkGreyEgg;
            color: transparent;
            background-color: $lightGreyBg;
            box-shadow: none;
        }
        &.error {
            border: 2px solid red;
        }
    }

    .input-absent {
        background: none;
        display: flex;
        margin: 0 20px;
        border: none;
        &.active {
            color: #ee8686;
        }
        &.inactive {
            color: $darkGreyEgg;
        }
        .icon {
            font-size: 1.75rem;
        }
    }

    .icon {
        font-size: 1.5rem;
        cursor: pointer;
    }
}
