.form-input {
    @include sombra;
    background: white;
    padding: 25px 20px;
    width: 100%;
    border-radius: $border-radius-sm;
    box-sizing: border-box;
    border: 1px solid $borders;
    font-weight: lighter;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: inherit;

    &:disabled {
        background: $lightGreyBg;
    }
    &.minor-heigth {
        height: 63px;
    }
}
.form-input-table {
    @include sombra;
    background: white;
    padding: 10px 10px;
    width: 100%;
    border-radius: $border-radius-sm;
    box-sizing: border-box;
    border: 1px solid $borders;
    font-weight: lighter;
    font-size: 1rem;
    line-height: 1rem;
    color: inherit;

    &:disabled {
        background: $lightGreyBg;
    }
    &.minor-heigth {
        height: 40px;
    }
}

fieldset {
    border: none;
    margin: 5px 0;
    padding: 0;
}

.form-input-white {
    color: #003750;
    border: 1px solid #eaeaea;
    padding: 0.75rem 1.125rem;
    border-radius: 0.5rem;
    font-weight: 600;
    height: 39.09px;
    width: 100%;
    font-size: 13px;
}

// Workaround where the letter 'g' gets cut off
select {
    &.form-input {
        line-height: 1.7rem;
    }
}

label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
}

.form-input-no-card {
    @extend .form-input;
    padding: 20px;
    margin-right: 10px;
    flex: 1;
    background-color: $lightGreyBg;
    color: $darkGreyEgg;
    box-shadow: none;
    font-weight: normal;
    font-size: 1rem;
    &::placeholder {
        color: $mediumGreyBg;
    }
}

.input-error-msg {
    color: red;
    text-align: left;
    transition: all 0.3s ease;
    margin-top: 5px;
    display: block;
}

.input-error {
    font-size: 0.9rem;
    color: #ff0033;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: white
        url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
        no-repeat 97% 50% !important;
    background-size: 5% !important;
    color: #000000;
    border: 1px solid #000000;
    &:after {
        content: 'hola';
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    overflow: visible;
}
