.student-import-container {
    // @include egg-global-block;
    .content {
        .steps-container {
            margin-top: 40px;
            display: flex;
            flex: 1;
            display: flex;
            .step {
                @extend .egg-card;
                flex: 1 1 0;
                padding: 40px;
                margin: 15px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                .icon {
                    font-size: 7rem;
                    color: $eggColor;
                }
                .title {
                    display: block;
                    font-weight: bold;
                    font-size: 2.5rem;
                    margin: 10px 0;
                }
                p {
                    flex-grow: 1;
                }
                button {
                    margin-top: 10px;
                }
            }
        }
    }
}
