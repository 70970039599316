@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap');

@import './normalize.css';
@import '../assets/fonts/stylesheet.css';
@import './globals/globalVariables';
@import './globals/globalCards';
@import './globals/globalForms';
@import './globals/globalButtons';
@import './globals/globalText';

//Other
@import '../components/WizardModal/wizard';
@import '../components/WizardModalAddUser/wizardModalAddStudent';
@import '../components/ConfirmModal';
// @import '../components/avatarEgg';
@import '../components/statsBlock';
@import '../components/studentsTable';
@import '../components/modalEgg';
@import '../components/linkBlockMsg';
@import '../components/pagination.scss';
@import '../components/ToastRotation.scss';

// Layout
@import '../layouts/mainLayout';
@import '../layouts/fullLayout';
@import '../layouts/header';
@import '../layouts/menu';
@import '../layouts/switch';
@import '../layouts/footer';

// Pages
@import '../pages/Other/loadingPage';
@import '../pages/Login/loginPage';
@import '../pages/Signup/signupPage';
@import '../pages/Share/sharePage.scss';
@import '../pages/Wizard/wizardSteps';
@import '../pages/Wizard/step1';
@import '../pages/Wizard/step2';
@import '../pages/Wizard/step3';
@import '../pages/Wizard/step4';
@import '../pages/NotificationCenter/notificationPage.scss';
@import '../pages/Enroll/enroll';
@import '../pages/Profile/profilePage';
@import '../pages/Signup/Wizard/fillInfoWizard';
@import '../pages/Verify/verifyPage.scss';
@import '../pages/Teams/Collab/TeamCollab.scss';

// Profile
@import '../pages/Profile/profileHeading';

@import '../pages/Password/passManagementPage.scss';
// Team Dashboard
@import '../pages/Teams/Dashboard/teamsCreate';
@import '../pages/Teams/Dashboard/teamsDashboard';
@import '../pages/WizardPulse/wizardPulse.scss';
//Team Details
@import '../pages/Teams/MandatoryVote/mandatoryVote';
@import '../pages/Teams/Detail/teamsDetail';
@import '../pages/Teams/Detail/teamsDetailStudent';
// Teams Settings
@import '../pages/Teams/Settings/teamSettings';
// Team Manager
@import '../pages/Team/teamManager';
// Team Manager Actions
@import '../pages/Teams/Manage/Components/Actions/rubrica';
@import '../pages/Teams/Manage/Components/Actions/note';
@import '../pages/Teams/Manage/Components/Actions/exam';
@import '../pages/Teams/Manage/Components/Actions/attendance';
@import '../pages/Teams/Manage/Components/Actions/stats';
@import '../pages/Teams/Manage/Components/Actions/reassign';

// Student Pages
@import '../pages/Students/Detail/studentDetail';
@import '../pages/Students/Import/studentImport';
@import '../pages/Students/Import/dataRevision';

@import '../pages/Teams/Detail/OnboardingWizard/OnboardingWizard.scss';

// Define breakpoints
$breakpoints: (
    sm: 768px,
    md: 992px,
    lg: 1200px,
);

// Simple media query mixin
@mixin respond-to($breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
        @content;
    }
}

body,
html {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: $darkGreyEgg;
    box-sizing: border-box;
}

body {
    min-height: 100vh;

    &:after {
        position: absolute;
        left: -1337px;
        visibility: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
        opacity: 0;
        font-size: 0;
        z-index: -1;

        @each $breakpoint-key, $breakpoint-value in $breakpoints {
            @if ($breakpoint-key == 'sm') {
                content: 'sm';
            } @else {
                @include respond-to($breakpoint-key) {
                    content: '#{$breakpoint-key}';
                }
            }
        }
    }
}

.main-container {
    height: 100%;
    min-height: 100vh;
    margin-left: 50px;
    padding: 3em 0px;
    background: #f8f8f8;

    @media (max-width: 768px) {
        height: 100vh;
        margin-left: 0;
        margin-top: 64px;
        padding: 7vw 10vw;

        .header {
            padding: 7vw 0 0;
            justify-content: left;
            background: inherit;

            & > * {
                text-align: left;
            }
        }
    }
}

[hidden] {
    display: none !important;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.my-node {
    background: red;
}

.my-node-enter {
    opacity: 0;
}

.my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.my-node-exit {
    opacity: 1;
}

.my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
    margin: 0;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
        opacity: 1;
    }
}

.dropdown.open {
    filter: drop-shadow(0 0 3px #d8d8d8);
}

.only-mobile {
    @media screen and (min-width: 769px) {
        display: none !important;
    }
}

.only-desktop {
    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

body > iframe[style*='2147483647'] {
    display: none !important;
}

// Print styles
@media print {
    .no-print {
        display: none;
    }
}

.flex {
    display: flex;
    gap: 5px;
}

.column {
    flex-direction: column;
}

.text-center {
    text-align: center;
}
