.overlayModal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: fixed;
    z-index: 11000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 205, 0, 0.8);
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 40px;
    border-radius: 15px;
    height: 50%;
    width: 80%;
    box-sizing: border-box;
    outline: 0;
    @media screen and (min-width: 768px) {
        height: auto;
        width: auto;
    }
}

.close-modal-btn {
    color: white;
    font-size: 2rem;
    position: absolute;
    top: 15px;
    right: 15px;
}
