.data-revision-container {
    .header {
        .yellow {
            color: $eggColor;
        }
    }
    .content {
        margin-top: 40px;
        // background-color: red;
        .egg-list {
            .list-item {
                display: flex !important;
                align-items: center;
                div {
                    width: 100%;
                    &:first-child {
                        max-width: 60px;
                        font-weight: bold;
                        display: none;
                        @media screen and (min-width: 768px) {
                            display: block;
                        }
                    }
                    margin: 5px;
                    flex-grow: 1;
                }
                input {
                    background-color: darken(white, 5%);
                    box-shadow: none;
                    // margin:5px;
                }
            }
        }
    }
    .info-footer {
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        .action {
            margin-top: 10px;
            @media screen and (min-width: 768px) {
                margin-left: auto;
            }
            button {
                margin: 5px;
            }
        }
    }
}
