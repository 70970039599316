.section {
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 1.5em;
}

.section .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section hr {
    width: 100%;
}

.section .header {
    display: flex;
    position: relative;
}

.closeButton {
    border: 0;
    background: 0;
    position: absolute;
    right: 0;
}

.closeButton svg {
    font-size: 18px;
    color: #073045;
}

.title {
    font-size: 1.1875rem;
    font-weight: 700;
    flex-grow: 2;
    text-align: center;
    color: #073045;
}

.confirm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
}

.confirm .top {
    display: grid;
    gap: 1rem;
    place-items: center;
    margin-top: 6rem;
}

.confirm .top svg {
    font-size: 2.5rem;
}

.confirm .top .text {
    width: 14.275rem;
    color: #003750;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;
}

.confirm .bottom {
    display: grid;
    gap: 1rem;
    place-items: center;
    width: 100%;
}

.confirm .bottom button {
    width: 100%;
}

.select {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}
.select select {
    margin-top: 5px;
    padding: 0.5rem;
    width: 100%;
    height: 42px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background: #fff;
}

.selectedTab {
    margin: 10px 0;
    margin-top: 0;
}
