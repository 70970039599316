.teamHeader {
    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @media only screen and (min-width: 768px) {
        flex-direction: row;
    }
}

@media only screen and (min-width: 768px) {
    .teamHeader {
        flex-direction: row;
    }
}

.teamLabel {
    display: flex;
    gap: 1em;
    align-items: center;
}

.teamGuide {
    text-transform: uppercase;
    font-size: 24px;
}

.teamHeaderOptions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
}

.btnShowMentors {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1em 1.5em;
    color: #37474f;
    font-size: 0.9rem;
    font-weight: bold;
    border: 1px solid #dadada;
    border-radius: 100px;
    cursor: pointer;
    text-transform: uppercase;
}
.btnShowMentors:hover {
    color: white;
    background-color: #37474f;
}
.btnShowMentors > svg {
    font-size: 20px;
}
