.egg-notification-applet {
    img {
        padding: 0;
        padding-right: 5px;
        height: 35px;
        @media screen and (min-width: 768px) {
            // border-left: 1px $mediumGreyBg solid;
            padding: 0 20px;
        }
    }
}

.egg-header {
    position: sticky;
    top: 0px;
    z-index: 5;
    height: 50px;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px #00000029;

    background-color: white;
    .back-to-grid {
        display: none;
        cursor: pointer;
        @media screen and (min-width: 768px) {
            display: block;
            border-right: 1px $mediumGreyBg solid;
        }
        .icon {
            font-size: 1.8rem;
            margin-right: 20px;
        }
    }
    .responsive-hamburguer {
        margin-left: 5px;
        display: block;
        background: none;
        border: none;
        font-size: 1.8rem;
        @media screen and (min-width: 768px) {
            display: none;
        }
        &.open {
            color: $eggColor;
        }
    }
    .notification-and-route {
        display: flex;
        flex: 1;
        align-items: center;
        .ruta-title {
            display: none;
            @media screen and (min-width: 768px) {
                display: block;
            }
        }
    }
    // Importante
    .navigation {
        margin: 0;
        flex: 1;
        justify-content: center;
        display: none;
        margin-bottom: 1px;
        @media screen and (min-width: 768px) {
            display: block;
            margin-left: -35px;
        }
        ul {
            display: flex;
            flex: 0.6;
            justify-content: space-around;
            height: 100%;
            margin: 0;
            padding-left: 0;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.93rem;
            letter-spacing: 0.05rem;
            list-style: none;
            div {
                width: 100%;
                text-align: center;
                margin: 0 10px;
                // background:black;
                flex: 1 1 0;
                @media screen and (min-width: 768px) {
                    min-width: 120px;
                }
                &.active {
                    li:after {
                        content: '';
                        display: block;
                        border-bottom: 3px solid $eggColor;
                        border-radius: 3px;
                        position: relative;
                        left: 0;
                        bottom: 3px;
                        -webkit-transition: 0.05s ease;
                        width: 100%;
                    }
                }
                li {
                    height: 50px;
                    box-sizing: border-box;
                    line-height: 50px;
                    border-bottom: 3px solid $lightGreyBg;
                    //// Option to only transition ease in out border bottom
                    // transition: border .3s ease-in-out;
                    // &:hover{
                    // 	border-bottom:5px solid $eggColor;
                    // }
                    &:after {
                        content: '';
                        display: block;
                        border-radius: 3px;
                        border-bottom: 3px solid $eggColor;
                        width: 0;
                        position: relative;
                        left: 0;
                        bottom: 3px;
                        -webkit-transition: 0.05s ease;
                        transition: 1s ease;
                    }
                    &:hover:after {
                        width: 100%;
                    }
                    a {
                        height: 50px;
                        width: 100%;
                        display: block;
                    }
                    button {
                        color: $darkGreyEgg;
                        padding-top: 10px;
                        height: 50px;
                        width: 100%;
                        display: block;
                        background: none;
                        border: none;
                        font-weight: bold;
                        font-size: 0.9rem;
                        letter-spacing: 0.08rem;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    // Fin importante
    .user-data {
        // background:blue;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (min-width: 1200px) {
            flex: 1;
        }
        .icon {
            font-size: 18px;
            // margin-right: 20px;
            cursor: pointer;
            :hover {
                color: rgb(95, 108, 114);
            }
        }
        .notifications-block {
            // background-color: red;
            margin-right: 20px;
            position: relative;
            .bell {
                font-size: 1.4rem;
            }
            .circle-container {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: -8px;
                right: -8px;
                .cant-notif {
                    font-weight: bold;
                    font-size: 0.67rem;
                    position: absolute;
                    color: white;
                    // left:0px;
                }
                .circle {
                    color: red;
                    padding: 2px;
                    font-size: 1.1rem;
                    // position:absolute;
                    // top:-3px;
                    // right:-7px;
                }
            }
        }
        .date {
            font-size: 0.85rem;
            margin-right: 25px;
            display: none;
            text-transform: capitalize;
            @media screen and (min-width: 1000px) {
                display: block;
            }
        }
        .add-team-btn-container {
            margin-right: 15px;
            .add-team-btn {
                @include sombra;
                background-color: white;
                border: 1px solid $borders;
                width: 50px;
                height: 50px;
                border-radius: 50px;
                font-size: 1.6rem;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &:hover {
                    background-color: $eggColor;
                    // color:$darkGreyEgg;
                }
            }
        }
        .search-icon {
            font-size: 1.6rem;
            margin-right: 25px;
        }
        .search-field {
            margin-right: 15px;
            input {
                border: 1px $mediumGreyBg solid;
                border-radius: 5px;
                height: 30px;
                padding: 0 10px;
            }
        }
        .dropdown-click {
            display: none;
            @media screen and (min-width: 768px) {
                display: block;
            }
            cursor: pointer;
            border-radius: 50%;
            &.connected {
                border: 3px solid rgb(15, 165, 15);
            }
            &.closed {
                border: 3px solid rgba(187, 15, 15, 0.6);
            }
            &.connecting,
            &.closing {
                border: 3px solid rgb(219, 206, 21);
            }
        }
        .dropdown {
            hr {
                width: 50%;
            }
            background-color: white;
            border: 1px solid $separadorLineas;
            position: absolute;
            top: 60px;
            right: 10px;
            width: 200px;
            border-radius: 15px;
            padding: 0px 5px;
            display: none;
            z-index: 4;
            &.show {
                display: block;
            }
            &::before {
                content: '';
                position: absolute;
                height: 13px;
                width: 13px;
                left: calc(78% - 1px);
                top: -23px;
                border: 11px solid transparent;
                border-bottom: 11px solid $separadorLineas;
            }
            &::after {
                content: '';
                position: absolute;
                height: 11px;
                width: 11px;
                left: 78%;
                top: -20px;
                border: 10px solid transparent;
                border-bottom: 10px solid white;
            }
            ul {
                margin: 0 5px;
                padding: 0;
                list-style: none;
                &.options-visible {
                }
                &.langs-visible {
                    li {
                        button {
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                }
                li {
                    background: $lightGreyBg;
                    border: 1px solid $borders;
                    border-radius: 3px;
                    margin: 10px 0px;
                    padding: 10px;
                    font-weight: bold;
                    text-align: center;
                    &:hover {
                        background-color: $eggColor;
                        color: $darkGreyEgg;
                    }
                    a {
                        width: 100%;
                        display: block;
                    }
                    button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $darkGreyEgg;
                        background: none;
                        border: none;
                        font-weight: bold;
                        cursor: pointer;
                        width: 100%;
                    }
                    .icon {
                        color: $mediumGreyBg;
                    }
                    &.active {
                        background-color: $eggColor;
                        .icon {
                            color: $darkGreyEgg;
                        }
                    }
                }
            }
            .lang-switch {
                text-align: center;
                display: flex;
                justify-content: center;
                padding: 10px 0;
                margin: 0 5px;
                margin-bottom: 10px;
                background: $lightGreyBg;
                border-radius: 5px;
                border: 1px solid $borders;
                &:hover {
                    background-color: $eggColor;
                    color: $darkGreyEgg;
                }
                button {
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    .icon {
                        font-size: 1rem;
                    }
                    span {
                        font-weight: bold;
                        margin-left: -15px;
                    }
                }
            }
        }
    }
}
