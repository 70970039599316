.collab-docs-heading,
.collab-edit-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .users-block {
        display: flex;
        align-items: center;
    }
}

.collab-edit-heading {
    margin-bottom: 20px;
    .back-title-block {
        display: flex;
        align-items: center;
        .back-btn {
            background: none;
            border: none;
            margin-right: 15px;
            font-size: 2.25rem;
            cursor: pointer;
        }
        .btn-edit-title {
            background: none;
            border: none;
            cursor: pointer;
        }
        .title-container {
            input {
                background: none;
                border: none;
                // border:1px solid black;
                height: 45px;
                font-size: 2.5rem;
                padding: 10px;
                width: auto;
                margin-right: 20px;
                background: white;
                border-radius: 10px;
                border: 1px solid $borders;
                &:disabled {
                    background: none;
                    border: none;
                }
            }
        }
    }
    .avatars-container {
        // margin-top: 30px;
        display: flex;
        .avatar {
            margin-right: -10px;
        }
    }
    .download-btn-block {
        padding-left: 30px;
        button {
            background: none;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
            i {
                display: block;
            }
        }
    }
}

.collab-docs-list {
    display: flex;
    margin-right: -10px;
    margin-left: -10px;
    flex-flow: column nowrap;
    @media screen and (min-width: 768px) {
        flex-flow: row wrap;
    }
    .list-item-card {
        padding: 40px 20px;
        box-sizing: border-box;
        margin: 10px;
        @media screen and (min-width: 768px) {
            flex-basis: calc(33% - 20px);
        }
        .list-item-content {
            position: relative;
            .dropdown-options {
                @include sombra;
                position: absolute;
                right: -33px;
                top: 10px;
                background-color: white;
                // width: 100px;
                // height: 60px;
                border-radius: 10px;
                border: 1px solid $borders;
                padding: 10px 20px 10px 10px;
                button {
                    width: 100%;
                    margin: 5px;
                    justify-content: start;
                }
                &::before {
                    content: '';
                    position: absolute;
                    height: 13px;
                    width: 13px;
                    left: calc(78% - 1px);
                    top: -23px;
                    border: 11px solid transparent;
                    border-bottom: 11px solid $separadorLineas;
                }
                &::after {
                    content: '';
                    position: absolute;
                    height: 11px;
                    width: 11px;
                    left: 78%;
                    top: -20px;
                    border: 10px solid transparent;
                    border-bottom: 10px solid white;
                }
            }
            .title {
                font-size: 1.6rem;
                background: none;
                border: none;
                margin-left: 0;
                padding-left: 0;
                margin-bottom: 10px;
                cursor: pointer;
            }
            .dates {
                .created-at {
                    font-size: 0.81rem;
                    .pencil-icon {
                        margin-right: 5px;
                        color: $darkGreyEgg;
                    }
                    color: $mediumGreyBg;
                    .by {
                        color: $darkGreyEgg;
                    }
                }
            }
            .avatars-container {
                margin-top: 30px;
                display: flex;
                .avatar {
                    margin-right: -10px;
                }
            }
            .unsubscribe-block {
                position: absolute;
                top: -20px;
                right: 0;
                button {
                    font-size: 1rem;
                    height: 20px;
                    width: 20px;
                    background: none;
                    border: none;
                    outline: none;
                    cursor: pointer;
                }
            }
        }
    }
}

.collab-page-container {
    display: flex;
    min-height: 650px;
    .editor-container {
        flex-grow: 2;
    }
    .docs-navigation {
        flex-grow: 1;
    }
}

// #fpad-container {
//     // width: 100%;
//     // height: 100%;
//     // background-color:#c5c5c5;
// }

.firepad {
    width: 100%;
    // background-color:whitesmoke;
}

.firepad-toolbar {
    border-bottom: none !important;
    .firepad-toolbar-wrapper {
        // Hidding first 3 dropdown elements of toolbar until have a solution
        .firepad-btn-group {
            &:nth-child(1) {
                display: none;
            }
            &:nth-child(2) {
                display: none;
            }
            &:nth-child(3) {
                display: none;
            }
            .firepad-dropdown {
                display: none !important;
            }
        }
    }
}

.CodeMirror.cm-s-default.CodeMirror-wrap {
    background: white;
    border: 1px solid $borders;
    // border-radius:30px;
    margin-top: 0px;
    padding: 30px;
    font-family: 'Eina', sans-serif;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 19px;
}

a.powered-by-firepad {
    display: none;
}

#userlist {
    .firepad-userlist {
        margin-right: 30px;
        border-right: 1px solid $borders;
        // background-color: white;
        border-radius: 5px;
        padding: 5px;
        boder: 1px solid $borders;
        .firepad-userlist-heading {
            margin: 0;
            margin-left: 19px;
            display: none;
        }
        .firepad-userlist-users {
            text-transform: capitalize;
            font-size: 0.9rem;
            input {
                background: none;
                border: none;
                margin-left: 0;
                padding-left: 0;
                text-transform: capitalize;
            }
        }
    }
}

.firepad-userlist-heading {
    margin: 20px 15px 0;
    font-size: 12px;
    font-weight: bold;
}

.firepad-userlist-user {
    display: flex;
    align-items: center;
}

.firepad-userlist-color-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    // border: 1px solid #ccc;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin-right: 10px;
}
