$eggColor: #ffcd00;
$darkGreyEgg: #073045;
$darkGreyEggHover: #05202e;
$mediumGreyBg: #d2d2d2;
$lightGreyBg: #f4f4f4;
$paragraphColor: $darkGreyEgg;
$primaryColor: #296efa;
$primaryColorHover: #105df9;
$primaryColorAlt: #427ca6;
$success: #3fb85d;
$successHover: #359b4e;
$danger: #ff6673;
$dangerHover: #ff4d5c;
$white: #ffffff;
$whiteHover: #f4f4f4;
$separadorLineas: #eaeaea;
$borders: lighten($mediumGreyBg, 10%);

$border-radius-lg: 20px;
$border-radius-md: 15px;
$border-radius-sm: 5px;

$header-height: 70px;
$footer-height: 90px;

:root {
    --eggColor: #ffcd00;
    --lightGrey: #e8ecee;
    --mediumGrey: #a7b8c0;
    --darkGreyEgg: #073045;
    --darkGreyEggHover: #05202e;
    --success: #3fb85d;
    --successHover: #359b4e;
    --danger: #ff6673;
    --dangerHover: #ff4d5c;
    --info: #296efa;
    --primaryColor: #296efa;
    --active: #2b75ff;
    --primaryColorHover: #105df9;
    --primaryColorAlt: #427ca6;
    --white: #ffffff;
    --whiteHover: #f4f4f4;
    --borders: lighten($mediumGreyBg, 10%);
    --separadorLineas: #eaeaea;
    --radius: 5px;
}

@mixin sombra {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.09);
}

.grey-bg {
    background: $lightGreyBg;
}

.egg-color-bg {
    background: $eggColor;
}

.responsive {
    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}

#sentry-feedback {
    --inset: 0em 0em auto auto;
}
