.full-layout {
    width: 100% !important;
    max-width: 100%;
    padding: 0;

    .container {
        display: flex;
        flex-direction: column;
        max-width: 1133px;
        margin: 0 auto;

        &.fill-gap {
            min-height: 50vh;
        }
    }
}
