.ul-especial {
    margin: 0 5px;
    padding: 0;
    list-style: none;
    min-width: 160px;
    position: absolute;
    &.langs-visible {
        li {
            button {
                display: flex;
                justify-content: space-between;
            }
        }
    }
    li {
        background: $lightGreyBg;
        border: 1px solid $borders;
        border-radius: 5px;
        margin: 10px 0px;
        padding: 10px;
        font-weight: bold;
        text-align: center;
        &:hover {
            background-color: $eggColor;
            color: $darkGreyEgg;
        }
        a {
            width: 100%;
            display: block;
        }
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $darkGreyEgg;
            background: none;
            border: none;
            font-weight: bold;
            padding: 1px;
            cursor: pointer;
            width: 100%;
            label {
                padding-right: 5px;
            }
        }
        .icon {
            color: $mediumGreyBg;
        }
        &.active {
            background-color: $eggColor;
            .icon {
                color: $darkGreyEgg;
            }
        }
    }
}

.label-special {
    margin-left: 10px;
}
