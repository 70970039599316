@font-face {
    font-family: 'Eina';
    src: url('Eina01-Light.woff2') format('woff2'), url('Eina01-Light.woff') format('woff'),
        url('Eina01-Light.svg#Eina01-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Eina';
    src: url('Eina01-Regular.woff2') format('woff2'), url('Eina01-Regular.woff') format('woff'),
        url('Eina01-Regular.svg#Eina01-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Eina';
    src: url('Eina01-SemiBold.woff2') format('woff2'), url('Eina01-SemiBold.woff') format('woff'),
        url('Eina01-SemiBold.svg#Eina01-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Eina';
    src: url('Eina01-Bold.woff2') format('woff2'), url('Eina01-Bold.woff') format('woff'),
        url('Eina01-Bold.svg#Eina01-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
