.card {
    position: relative;
    border-radius: var(--monitor-card-rounded);
    background: var(--monitor-card-background);
    padding: 2em;
    display: grid;
    align-content: stretch;
}

.cardInfo {
    text-align: left;
}

.text {
    margin: 0;
}
