.events {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.section {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.7rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    height: 1.8125rem;
    flex-grow: 1;
    gap: 0.5rem;
    width: 100%;
}

.section.currentEvent {
    background-color: #15c1b0;
    color: white;
}

.section.nextEvent {
    background-color: #cedbe1;
    color: #003750;
}
