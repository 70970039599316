.teamRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    padding: 2rem 2.5rem;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.isExpanded {
    border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    -ms-border-radius: 10px 10px 0 0;
    -o-border-radius: 10px 10px 0 0;
}

.teamInfo {
    min-width: 250px;
    display: grid;
    grid-template-columns: 50% 25% 15% 10%;
    align-items: center;
    gap: 1rem;
}

.teamName {
    font-size: 1.5rem;
    font-weight: 600;
}
.members {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.members > span {
    font-size: 1.2rem;
    font-weight: 600;
}

.membersCount {
    display: flex;
}
.boldRed {
    color: var(--color-danger);
    font-weight: bold;
}

.buttons {
    display: flex;
    gap: 1rem;
}

.arrow {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
    font-size: 16px;
}
