.user-action-rubrica {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
        justify-content: flex-end;
    }
    .stars-block {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-start;
        padding: 0 10px;
        @media (min-width: 768px) {
            flex: 0;
            justify-content: flex-start;
            .slash {
                padding-left: 0px;
            }
        }
        .star-icon {
            font-size: 1.4rem;
            margin: 0 5px;
            @media screen and (min-width: 768px) {
                margin: 0 13px;
            }
        }
        .star-icon:hover {
            cursor: pointer;
        }
        .active {
            color: $eggColor;
            // path {
            //     stroke-width: 25;
            //     stroke: #37474F
            // }
        }
    }
    .qualify-block {
        .btn-qualify {
            @extend .btn-yellow;
            padding: 10px 30px;
            border-radius: 30px;
            font-size: 0.9rem;
            margin-left: 20px;
            margin-right: 20px;
            border: none !important;
            border: 1px solid $eggColor !important;
            &:hover {
                opacity: 1;
                background: $darkGreyEgg;
                color: white;
                border: 1px solid $darkGreyEgg !important;
            }
            &:disabled {
                opacity: 1;
                background-color: $lightGreyBg;
                color: $mediumGreyBg;
                border: 1px solid $borders !important;
            }
        }
    }
    .average-block {
        flex-basis: 15%;
        text-align: center;
        display: none; // #TEMP - BLOQUE OCUTO TEMPORALMENTE EN MOBILE, AJUSTAR Y HABILITAR LUEGO
        // width:100%;
        @media screen and (min-width: 768px) {
            display: block;
        }
        .icon {
            font-weight: normal;
            font-size: 1.3rem;
            margin-left: 10px;
        }
    }
}
