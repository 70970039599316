.pass-management-page {
    min-height: 100vh;
    background: $eggColor;
    display: flex;
    justify-content: center;
    align-items: center;
    .pass-container {
        width: 100%;
        @media screen and (min-width: 768px) {
            width: 80%;
        }
    }
}
