.modal-confirm {
    background-color: white;
    min-height: 380px;
    min-width: 580px;
    max-width: 750px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 0px solid #eaeaea;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    .modal-div {
        min-height: inherit;
        padding: 40px;
        display: grid;
        grid-template-rows: max-content auto max-content;
        gap: 4rem;
    }
    .modal-title {
        display: flex;
        gap: 2em;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .modal-content {
        height: inherit;
        font-size: 1.3rem;
        & > .content-text {
            word-wrap: break-word;
        }
    }
    .div-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .btn-modal-cancel {
        border-radius: 8px;
        font-size: 13px;
        font-weight: bold;
        background: none;
        color: $darkGreyEgg;
        border: 1px solid #eaeaea;
        margin-right: 20px;
        height: 36px;
        width: 100px;

        &:hover {
            opacity: unset;
            background-color: $whiteHover;
        }
    }
    .btn-modal-confirm {
        border-radius: 8px;
        font-size: 13px;
        font-weight: bold;
        color: #eaeaea;
        background: $primaryColor;
        border: 1px solid #eaeaea;
        height: 36px;
        width: 100px;
        &:hover {
            opacity: unset;
            background-color: $primaryColorHover;
        }
    }
}

.modal-title .normal {
    font-size: 25px;
    display: none;
}
.modal-title .warning {
    font-size: 25px;
    color: #f29423;
}
.modal-title .danger {
    font-size: 25px;
    color: #ec5b62;
}

.modal-report {
    background-color: white;
    min-height: 280px;
    min-width: 480px;
    max-width: 900px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 0px solid #eaeaea;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    @media screen and (max-width: 768px) {
        max-width: 100%;
        padding: 5px;
    }
    .modal-div {
        margin: 40px;
    }
    .modal-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
    }
    .modal-content {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            text-align: center;
        }
    }
    .div-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 30px;
    }
    .btn-modal-cancel {
        @extend .btn-yellow;
        font-size: 14px;
        background: none;
        color: $darkGreyEgg;
        border: 1px solid #eaeaea;
        margin-right: 20px;
        max-height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .btn-modal-confirm {
        @extend .btn-yellow;
        font-size: 14px;
        color: #eaeaea;
        background: #5384ff;
        border: 1px solid #eaeaea;
        max-height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.modal-dates {
    background-color: white;
    min-height: 280px;
    min-width: 480px;
    max-width: 480px;
    min-height: 380px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 0px solid #eaeaea;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .modal-div {
        margin: 40px;
    }

    .modal-title {
        display: flex;
        gap: 2em;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        font-size: 18px;
        font-weight: 900;
        color: #37474f;
    }
    .modal-content {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 600;
        margin: 20px 0 20px 0;
        line-height: 1.5;
    }
    .modal-range {
        display: flex;
        flex-direction: row;
        height: 50%;
    }
    .modal-range-input {
        display: flex;
        flex-direction: column;
        margin: 20px 0 20px 0;
        font-size: 14px;
        font-weight: 900;

        .spanUpperDate {
            text-transform: uppercase;
            margin-left: 10px;
            font-size: 10px !important;
            font-weight: bold;
        }

        .inputDates {
            border: 1px solid #f2f2f2 !important;
            min-width: 190px;
            height: 36px;
            text-align: center;
            padding: 20px;
            border-radius: 10px;
            margin: 5px;
            color: #073045;
            font-size: 14px;
            font-weight: 600;
        }
    }
    .modal-big-date {
        display: flex;
        flex-direction: row;
    }
    .modal-big-date-picker {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 20px 0;
        font-size: 14px;
        font-weight: 900;
        padding: 18px;
        width: 100%;

        .spanUpperDate {
            margin-left: 10px;

            font-size: 17px !important;
            font-weight: bold;
        }

        .inputDates {
            border: 1px solid #f2f2f2 !important;
            min-width: 174px;
            height: 36px;
            text-align: center;
            padding: 20px;
            border-radius: 10px;
            margin: 5px;
            color: #073045;
            font-size: 14px;
            font-weight: 600;
        }
    }
    .div-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;
        bottom: 20px;
        right: 20px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .btn-modal-cancel {
        border-radius: 8px;
        font-size: 13px;
        font-weight: bold;
        background: none;
        color: $darkGreyEgg;
        border: 1px solid #eaeaea;
        margin-right: 20px;
        height: 36px;
        width: 100px;

        &:hover {
            opacity: unset;
            background-color: $whiteHover;
        }
    }
    .btn-modal-confirm {
        border-radius: 8px;
        font-size: 13px;
        font-weight: bold;
        color: #eaeaea;
        background: $primaryColor;
        border: 1px solid #eaeaea;
        height: 36px;
        width: 100px;
        &:hover {
            opacity: unset;
            background-color: $primaryColorHover;
        }
    }
}
