.asistencia-block {
    padding-top: 20px !important;
    @include egg_general_block;
    .icon {
        align-self: flex-end;
        font-size: 2rem;
        margin-bottom: -2.14rem;
    }
    .check .icon {
        color: #7de780;
    }
    .clock .icon {
        color: #66ccff;
    }
    .slash .icon {
        color: #ee8686;
    }
    .number {
        display: block;
        font-size: 7.5rem;
        color: $eggColor;
        font-weight: bold;
        letter-spacing: -0.5rem;
        margin-top: -2.14rem;
        margin: 1rem 0 0 0;
    }
    .label {
        display: block;
        font-size: 1.7rem;
        font-weight: bold;
        margin-bottom: 2rem;
    }
}
