.overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.7);
}
.container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 84px auto;
    height: 100%;
}
.header {
    background-color: #37474f;
    color: white;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    padding: 0 3em;
    gap: 20px;
}

.teamTitle {
    display: flex;
    align-items: baseline;
    gap: 20px;
}

.headerIcon {
    background-color: #a115d5;
    border-radius: 100%;
    padding: 13px;
    color: white;
    font-size: 22px;
}

.guide {
    font-size: 15px;
    color: #bababa;
    text-transform: uppercase;
}

.btnCloseModal {
    font-size: 28px;
    background: 0;
    border: 0;
    outline: 0;
    color: white;
    margin-left: auto;
    cursor: pointer;
}
.content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 2em;
    padding: 1em;
}
.mentorList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 90px;
    align-items: center;
    border-right: 1px solid #eaeaea;
}

.mentoItem {
    font-size: 16px;
    display: flex;
    align-items: center;
    height: fit-content;
    gap: 1em;
    margin: 1em 0;
}
.mentoItem > div:nth-child(1) {
    border-radius: 100%;
    overflow: hidden;
    height: 56px;
    width: 56px;
    display: grid;
    place-items: center;
    background: #f8f8f8;
    border: 1px solid #eeeeee;
}
.mentoItem svg {
    font-size: 2rem;
    color: #d2d2d2;
}
.mentoItem img {
    border-radius: 100%;
    overflow: hidden;
    height: 56px;
}

.mentoItem .mentoTitle {
    font-size: 11px;
    text-transform: uppercase;
    margin: 3px 0;
}

@media screen and (max-width: 900px) {
    .content {
        grid-template-columns: 2fr 1fr;
        grid-gap: 1em;
    }
    .mentorList {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 760px) {
    .container,
    .content,
    .teamTitle,
    .mentorList {
        display: block;
    }
    .header {
        padding: 1em;
    }
    .headerIcon {
        display: none;
    }
    .container {
        display: flex;
        flex-direction: column;
    }
    .content {
        flex-grow: 2;
        overflow: auto;
    }
    .mentorList {
        border: 0;
        border-bottom: 1px solid #eaeaea;
    }
}
