.step-3-container {
    .subjects-grid {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        .subject-item {
            flex-basis: 100%;
            flex-grow: 1;
            @media screen and (min-width: 500px) {
                flex-basis: calc(50% - 30px);
                flex-grow: 0;
            }
            @media screen and (min-width: 768px) {
                flex-basis: calc(33.33% - 30px);
                flex-grow: 1;
            }
            @media screen and (min-width: 975px) {
                flex-grow: 0;
            }
            margin: 10px;
            padding: 6%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .subject-icon {
                font-size: 3.14rem;
                color: $eggColor;
                margin-bottom: 30px;
            }
            .subject-label {
                display: block;
                font-weight: bold;
                font-size: 1.3rem;
            }
            &.add {
                background: $mediumGreyBg;
                box-shadow: none;
                flex-grow: 0;
                @media screen and (min-width: 975px) {
                    flex-basis: calc(33% - 15px);
                }
                .subject-icon {
                    color: $darkGreyEgg;
                }
            }
            &:hover {
                transition: box-shaddow 0.5s ease-in-out;
                cursor: pointer;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
            }
            &.selected {
                box-shadow: 0px 0px 15px 0px rgba(255, 205, 0, 0.5);
            }
        }
    }
}
