.coachItem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    justify-content: space-between;
    padding: 30px 0 5px;
}

.coachItem:not(:first-child) {
    border-bottom: none;
}

.info {
    display: flex;
    align-items: center;
    gap: 30px;
}
