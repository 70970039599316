.label {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.spinner {
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inputs {
    display: grid;
    grid-template-columns: 100px auto;
    gap: 1em;
}

.input {
    padding: 1em 0.8em;
    border: 0.0625rem solid #eaeaea;
    border-radius: 0.5em;
    font-size: 14px;
    outline: 0;
}
.inputDay {
    padding: 5px !important;
    padding-left: 20px !important;
    text-align: center;
}
