.team-manager-container {
    @include egg_general_block;
    // @extend .container;
    margin: 0px !important;
    padding: 14px 0px !important;
    background-color: '#F8F8F8';

    .container {
        .sticky {
            background-color: '#F8F8F8';
            position: sticky;
            z-index: 1;
            top: 0;
            padding: 1em 0;
            display: flex;
            flex-direction: column;
            gap: 40px;

            .action-view-switcher {
                display: flex;
                justify-content: center;

                .buttons-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-basis: 0;
                    list-style: none;
                    background-color: $lightGreyBg;
                    border-radius: var(--radius);
                    padding: 0;
                    margin: 0;

                    @media screen and (min-width: 768px) {
                        flex-direction: row;
                        flex: 0.7;
                    }

                    li {
                        flex: 1 1 0;
                        margin: 3px 0px;
                        padding: 0 10px;

                        &:not(:last-child) {
                            border-right: 1px solid $borders;
                        }

                        button {
                            cursor: pointer;
                            background: 0;
                            border: none;
                            padding: 8px 20px;
                            font-size: 12px;
                            letter-spacing: 0.1rem;
                            width: 100%;
                            margin: 3px 0;

                            &:hover {
                                background: darken(white, 10%);
                                border-radius: 5px;
                            }

                            &.selected {
                                background: $darkGreyEgg;
                                color: white;
                                border-radius: 5px;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }

        .content-switcher {
            ul {
                list-style: none;
                padding: 0px;
                display: flex;
                gap: 1.5em;
                font-size: 16px;
                font-weight: 500;

                li {
                    cursor: pointer;
                    border-bottom: 3px solid;
                    padding-bottom: 7px;
                    border-color: #dadada;

                    &:hover {
                        border-color: inherit;
                    }

                    &.active {
                        border-color: var(--eggColor);
                        font-weight: bold;
                    }
                }
            }
        }

        .zoomHybrid {
            background-color: white;
            padding: 12px;
            border-radius: 10px;

            span {
                font-size: 12px;
                text-transform: capitalize;
            }

            .action-link {
                margin: 0 5px;

                .icon {
                    margin: 0 10px 0 0;
                }
            }
        }
    }

    .sticky_search {
        @media screen and (min-width: 768px) {
            position: sticky;
            top: 0;
            background: white;
            z-index: 1;
            padding: 1em 0;
            display: flex;
            flex-direction: column;
            gap: 1.5em;
        }
    }

    .heading {
        .block-title {
            h2 {
                letter-spacing: 0.08rem;
            }

            .cant-badge {
                background-color: $eggColor;
                border-radius: 5px;
                padding: 5px 10px;
                color: white;
                margin-top: 15px;
                display: inline-block;
            }
        }
    }

    .content {
        flex-direction: column;
        gap: 60px;
        margin-top: 26px;

        .empty {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }

        .team-list {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .search-form {
            width: 100%;
            position: relative;
            margin-top: 0px;

            @media screen and (min-width: 768px) {
                margin-bottom: 30px;
            }

            input {
                position: relative;
            }

            .icon {
                font-size: 2rem;
                position: absolute;
                top: 25px;
                right: 30px;
            }
        }

        .search-results {
            border: 1px solid $separadorLineas;
            padding: 20px;
            border-radius: 20px;
            margin-top: 40px;
            margin-bottom: 50px;

            .heading {
                display: flex;
                justify-content: space-between;
                padding: 0 20px;

                .icon {
                    font-size: 28px;
                }
            }
        }
    }
}

.attendance-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    // background-color: red;
    margin-top: 30px;
    margin-bottom: 15px;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 20px;
    }

    .span-title {
        color: #073045;
        font-size: 14px;

        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    .span-stats {
        color: #999999;
        font-size: 13px;
    }
}

.attendance-view {
    display: grid;
    grid-template-columns: repeat(5, 1fr) 100px;
    gap: 10px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    .items-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 150px;
        min-height: 90px;
        border-radius: 5px;
        padding: 5px;
        border: 1px solid #eaeaea;

        svg {
            font-size: 20px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .items-block-spam {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #073045;

        .number {
            font-size: 2.3rem;
            font-weight: bold;
        }

        .subtitle {
            font-size: 1rem;
            text-align: center;
            min-height: 33px;

            @media screen and (max-width: 768px) {
                min-width: 150px;
            }
        }
    }

    .items-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: 0;
        color: inherit;
        width: 10%;
        height: 90px;
        border-radius: 5px;
        border: 1px solid #eaeaea;
        cursor: pointer;

        .icon-style {
            font-size: 19px;

            @media screen and (max-width: 768px) {
                margin-bottom: 10px;
            }
        }

        & span {
            margin-top: 5px;
        }

        &:hover {
            background-color: rgb(248, 248, 248);
            //background-color: red;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .check {
        margin-left: 10px;
        color: #7de780;
    }

    .clock {
        margin-left: 10px;
        color: #66ccff;
    }

    .slash {
        margin-left: 10px;
        color: #ee8686;
    }
}

.unassigned-students-list {
    margin-top: 10px;
    // margin-bottom: 50px;
}

.professor-list {
    margin-top: 10px;
    //margin-bottom: 20px;
}

.report-action {
    position: relative;
    height: 40px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

    .dropdown-icon {
        color: $darkGreyEgg;
        font-size: 0.75rem;
        margin-left: 5px;
        transition: transform 300ms ease;

        &.inactive {
            transform: rotate(0deg);
        }

        &.active {
            transform: rotate(180deg);
        }
    }

    .report-dropdown {
        z-index: 3;

        &::after {
            content: '';
            border: 11px transparent solid;
            border-bottom: 11px white solid;
            position: absolute;
            top: -20px;
            left: 50%;
        }

        &::before {
            content: '';
            border: 9.5px transparent solid;
            border-bottom: 9.5px $separadorLineas solid;
            position: absolute;
            top: -20px;
            left: calc(50% + 1.5px);
        }

        width: 250px;
        position: absolute;
        background-color: white;
        border-radius: $border-radius-md;
        top: calc(100% + 5px);
        border: 1px solid $separadorLineas;
        pointer-events: all;
        transition: opacity 300ms ease;
        opacity: 1;

        &[hidden] {
            display: unset !important;
            opacity: 0;
            pointer-events: none;
        }

        .report-item {
            border-bottom: 1px $separadorLineas solid;
            text-transform: none;
            padding: 15px 20px;

            &:hover {
                opacity: 1;
            }

            cursor: pointer;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .dropdown-meeting {
        z-index: 2;

        &::after {
            content: '';
            border: 11px transparent solid;
            border-bottom: 11px white solid;
            position: absolute;
            top: -20px;
            left: calc(50% - 11px);
        }

        &::before {
            content: '';
            border: 9.5px transparent solid;
            border-bottom: 9.5px $separadorLineas solid;
            position: absolute;
            top: -20px;
            left: calc(50% - 10px);
        }

        width: max-content;
        min-width: 100%;
        left: 50%;
        transform: translatex(-50%);
        position: absolute;
        background-color: white;
        border-radius: 8px;
        top: calc(100% + 15px);
        border: 1px solid $separadorLineas;
        opacity: 1;
        padding: 0px 5px 0px 5px;

        &[hidden] {
            opacity: 0;
            display: none;
            pointer-events: none;
        }

        .span-meeting {
            font-size: 13px;
            font-weight: 500;
            color: #003750;
            margin-left: 6px;
        }

        .action-link-meeting {
            width: 100%;
            padding: 10px;
            display: flex;
            align-items: flex-start;
            background-color: white;
            border: none;

            &:hover {
                opacity: 1 !important;
                border-radius: 5px;
                background-color: #f2f2f2;
            }
        }

        .report-item {
            border-bottom: 1px $separadorLineas solid;
            min-height: 45px;
            text-transform: none;
            display: flex;
            align-items: center;

            &:hover {
                opacity: 1;
            }

            cursor: pointer;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.manager-action-header {
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .separator {
        height: 20px;
        flex-grow: 1;
    }

    .exam-date {
        display: flex;
        height: 100%;
        width: 200px;
        margin: 5px;
        align-items: center;
        padding: 10px 15px;
        font-size: 1.2rem;
        align-self: center;
        border-radius: $border-radius-sm;
        border: 1px solid $mediumGreyBg;

        &.error {
            border: 2px solid red;
        }
    }
}

.action-link {
    position: relative;

    &.disabled {
        //pointer-events: none;
        opacity: 0.5;
        cursor: default;

        &:hover {
            opacity: 1;
        }
    }

    &:hover {
        opacity: 1 !important;
    }
}

.tables-list {
    position: relative;
    margin-top: 0px;

    .table-item:not(:first-child) {
        margin: 50px 0;

        .heading {
            display: flex;
            margin-bottom: 0px;

            .label-and-meeting {
                display: flex;
                flex-direction: row;
                font-size: 1.6rem;
                margin: 0;
                margin-bottom: 20px;
                gap: 1em;
                align-items: center;
            }
        }
    }
}

.user-item {
    border-bottom: 1px solid $separadorLineas;

    &:last-child {
        border-bottom: 1px solid #f2f2f2;
        border-radius: 0 0 10px 10px;
    }

    @media screen and (min-width: 768px) {
        display: flex;
        align-items: auto;
        flex-direction: row;
        align-items: center;
    }

    .user-info-block {
        float: left;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 30px 0;

        &:last-child {
            border-bottom: none;
        }

        @media screen and (min-width: 768px) {
            width: 75%;
            flex-basis: 50%;
            flex: 1;
            order: 0;
            width: auto;
        }

        .position {
            font-weight: bold;
            font-size: 1.4rem;
            padding: 0 20px;
            border-right: 1px solid $mediumGreyBg;
            height: 2.5rem;
            line-height: 2.5rem;

            &.unassigned {
                display: none;
            }
        }

        .avatar {
            position: relative;
            padding: 0 5px 0 20px;

            .voted-badge {
                //width: 15px;
                //height: 15px;
                position: absolute;
                top: -4px;
                right: 2px;
                //background-color: green;
                //border-radius: 50%;
                //color: $eggColor;
                color: darken($eggColor, 5);
                font-size: 1.1rem;

                .tooltip {
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity 200ms ease-in-out;
                    width: 180px;
                    border-radius: 10px 10px 10px 0px;
                    text-align: center;
                    position: absolute;
                    display: inline-block;
                    background-color: $darkGreyEgg;
                    color: lighten($eggColor, 20);
                    bottom: 6px;
                    left: 30px;
                    padding: 10px;

                    &:after {
                        position: absolute;
                        bottom: 0px;
                        left: -8px;
                        margin: auto;
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 4px;
                        border-color: transparent $darkGreyEgg $darkGreyEgg transparent;
                    }
                }

                &:hover {
                    .tooltip {
                        opacity: 1;
                    }
                }
            }
        }

        .name-label {
            flex: 2;
            padding: 0 10px;

            span {
                font-size: 17px;
            }

            a {
                text-decoration: none;
                color: $darkGreyEgg;

                &:hover {
                    opacity: 0.8;
                }
            }

            .badge {
                border-radius: 4px;
                padding: 5px;
                margin-top: 3px;
                display: inline-block;
                margin-left: 10px;
                font-size: 0.8rem;

                &.assigned {
                    background-color: $eggColor;
                }

                &.unassigned {
                    background-color: #ee8686;
                }
            }
        }
    }

    .action-block {
        margin-left: auto;
        flex-basis: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 3rem;
        padding: 10px 0 10px;

        display: grid;
        grid-template-columns: 1fr max-content;
        justify-items: end;

        @media screen and (min-width: 768px) {
            flex-basis: 50%;
        }
    }

    .average-block {
        float: right;
        width: 25%;

        span {
            font-size: 2.5rem;
            font-weight: bold;
            margin-left: 20px;
        }

        @media screen and (min-width: 768px) {
            order: 2;
            width: auto;
        }

        .delete-icon {
            font-size: 1.5rem;
            color: $eggColor;
        }
    }

    .delete-block {
        // width: 100%;
        margin-left: auto;
        text-align: right;
        cursor: pointer;

        .delete-icon {
            font-size: 1.5rem;
            color: $eggColor;
        }
    }

    .moreOptions {
        position: relative;

        button {
            background: 0;
            outline: 0;
            color: $darkGreyEgg;
            border: 1px solid #eaeaea;
            border-radius: 100%;
            width: 35px;
            height: 35px;
            cursor: pointer;

            &:hover,
            &.active {
                border-color: $darkGreyEgg;
            }
        }

        ul {
            position: absolute;
            top: 110%;
            left: -1%;
            border: 1px solid #eaeaea;
            margin: 0;
            padding: 7px;
            list-style: none;
            border-radius: 8px;
            margin-top: 6px;
            width: max-content;
            background-color: white;
            z-index: 1;

            &::before {
                content: '';
                border: 9.5px transparent solid;
                border-bottom: 9.5px #eaeaea solid;
                position: absolute;
                top: -20px;
                left: 8.5px;
            }

            &::after {
                content: '';
                border: 11px transparent solid;
                border-bottom: 11px white solid;
                position: absolute;
                top: -20px;
                // left: 50%;
            }

            li {
                padding: 10px 20px;
                border-bottom: 1px solid #eaeaea;
                cursor: pointer;

                &.danger {
                    font-weight: bold;
                    color: #f86969;
                }

                &:last-child {
                    border: 0;
                }
            }
        }
    }

    .student-vote-block {
        margin-left: auto;

        .btn-votar {
            display: flex;
            align-items: center;
            background-color: $lightGreyBg;
            color: $mediumGreyBg;
            border: 1px solid $borders;
            border-radius: 20px;
            // border:none;
            padding: 10px 20px;
            font-weight: bold;
            cursor: pointer;

            .icon {
                margin-right: 8px;
                color: $mediumGreyBg;
            }

            &:hover {
                transition: all 0.1s ease-in-out;
                background-color: $darkGreyEgg;
                color: white;
                border: 1px solid $darkGreyEgg;

                .icon {
                    color: white;
                }
            }

            &:disabled {
                pointer-events: none;
            }

            &.voted {
                background-color: $eggColor;
                color: $darkGreyEgg;
                border: 1px solid $eggColor;

                .icon {
                    color: white;
                }
            }
        }
    }
}
.peopleResults {
    border-top-color: #f2f2f2 !important;
}

.grey-block-back {
    background-color: $lightGreyBg;
}

.unassigned-grey-block {
    margin-top: 0px;
    background-color: $lightGreyBg;
    border-radius: var(--radius);
    padding: 3em;

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }

    .container {
        margin-top: 2em;
        padding: 0;

        .pagination-container {
            .pagination {
                margin-top: 70px;
            }
        }
    }
}

.pagination-container {
    width: 100%;
    // background-color: red;
    margin-bottom: 100px;
    text-align: center;

    .pagination-btn {
        border: none;
        background: none;
        margin: 5px;
        font-weight: bold;
        cursor: pointer;

        &.active {
            color: $eggColor;
        }
    }
}
