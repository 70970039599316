@import url('./styles.css');

.card {
    position: relative;
    border-radius: var(--monitor-card-rounded);
    background: var(--monitor-card-background);
    padding: 2em;
    display: grid;
    align-content: stretch;
    font-weight: 500;
}

.bars {
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.label .icon {
    font-size: 16px;
}
.label .caption {
    width: 100%;
}
.label .value {
    font-size: 20px;
}
.label .percentage {
    color: #c7c7c7;
}

.progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 10px;
    margin: 10px 0;
    border: 0;
    border-radius: 5px;
}
.progress::-webkit-progress-bar {
    border-radius: 5px;
}

.progress::-moz-progress-bar {
    border-radius: 5px;
}

.progress::-webkit-progress-value {
    border-radius: 5px;
}

.success .progress::-moz-progress-bar {
    background-color: var(--color-success);
}
.success .progress::-webkit-progress-value {
    background-color: var(--color-success);
}
.success .progress {
    background-color: var(--color-success-light);
}
.success .progress::-webkit-progress-bar {
    background-color: var(--color-success-light);
}

.info .progress::-moz-progress-bar {
    background-color: var(--color-info);
}
.info .progress::-webkit-progress-value {
    background-color: var(--color-info);
}
.info .progress {
    background-color: var(--color-info-light);
}
.info .progress::-webkit-progress-bar {
    background-color: var(--color-info-light);
}

.danger .progress::-moz-progress-bar {
    background-color: var(--color-danger);
}
.danger .progress::-webkit-progress-value {
    background-color: var(--color-danger);
}
.danger .progress {
    background-color: var(--color-danger-light);
}
.danger .progress::-webkit-progress-bar {
    background-color: var(--color-danger-light);
}

.egg .progress::-moz-progress-bar {
    background-color: var(--color-egg);
}
.egg .progress::-webkit-progress-value {
    background-color: var(--color-egg);
}
.egg .progress {
    background-color: var(--color-egg-light);
}
.egg .progress::-webkit-progress-bar {
    background-color: var(--color-egg-light);
}
