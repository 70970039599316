.teams-create-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    .content {
        text-align: center;
        width: 90%;
        @media screen and (min-width: 768px) {
            width: 50%;
        }
        h3 {
            text-align: center;
        }
        img {
            max-width: 80%;
            margin-bottom: 20px;
            @media screen and (min-width: 768px) {
                max-width: 400px;
            }
        }
    }
}
