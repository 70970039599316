.container {
    font-size: 16px;
    color: var(--darkGreyEgg);
    padding: 0 95px 60px;
}

.container header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentDesc {
    margin: 10px 0 30px 0;
    font-size: 1rem;
}

.itemDay {
    border-radius: 10px;
    padding: 5px 0;
    border: 1px solid #eaeaea;
    background-color: #fcfcfc;
    margin: 10px 0;
    padding: 1em;
}

.itemDay header {
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemDay header b {
    color: #37474f;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0px;
}

.itemDay header button {
    padding: 0;
    border: 0;
    background: 0;
    color: var(--darkGreyEgg);
    min-width: unset;
}

.range {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3em;
    text-align: right;
    color: #999999;
    padding-bottom: 5px;
}

.range.disable {
    opacity: 0.4;
}

.rangeTimes {
    color: #003750;
    flex-grow: 2;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: center;
}

.rangeButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    min-width: 50px;
}

.rangeButtons button {
    background: 0;
    border: 0;
    padding: 0;
    min-width: unset;
}

.range input[type='text'] {
    background: 0;
    border-bottom: 1px solid #eaeaea;
    border: 0;
    color: #003750;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 22px;
    outline: 0;
    text-transform: capitalize;
}

.range input::placeholder {
    color: #999999;
    font-weight: 500;
}
.timeFrom {
    text-transform: capitalize;
}
.timeTo {
    display: inline-block;
    text-align: center;
}
.range input[type='time'] {
    border-radius: 10px;
    border: 1px solid #eaeaea;
    color: #003750;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    margin: 0 10px;
    min-width: 125px;
    outline: 0;
    padding: 5px 20px;
}

.range input[type='time']::-webkit-calendar-picker-indicator {
    font-size: 14px;
}

.range input[type='time']:invalid,
.range input[type='time'].danger {
    font-size: inherit;
    border-color: #ff8181;
    outline-color: #ff8181;
}

.btnAddNewSchedule {
    color: var(--darkGreyEgg);
    cursor: pointer;
}
.btnRemoveNewSchedule {
    color: var(--danger);
    cursor: pointer;
}

.btnContainer {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    margin-top: 30px;
}

.btnContainer button {
    cursor: pointer;
    border-radius: 5px;
    padding: 15px 30px;
    font-weight: bold;
}

.btnCancel {
    color: inherit;
    border: 1px solid #eaeaea;
    background-color: var(--white);
}

.btnCancel:hover {
    background-color: var(--whiteHover);
}

.btnSave {
    color: var(--white);
    border: 1px solid var(--primaryColor);
    background-color: var(--primaryColor);
}

.btnSave:hover {
    border: 1px solid var(--primaryColorHover);
    background-color: var(--primaryColorHover);
}

.startNextDay {
    font-size: 13px;
    margin: 0 0 10px 0;
}
