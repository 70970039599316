.onboarding-wizard {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 90%;
    outline: none;
    @media screen and (min-width: 768px) {
        width: 70%;
        max-height: 90%;
    }
    border-radius: 30px;
    border: none;
    padding: 40px 0px 60px;
    position: absolute;
    background: white;
    .onboarding-slider {
        text-align: center;
        .slider-item {
            height: 100%;
            @media screen and (min-width: 768px) {
                display: flex !important;
                flex-direction: row;
                align-items: center;
            }
            outline: none;
            // background-color: blue;
            .slide-content {
                // background-color: red;
                padding: 0 10%;
                @media screen and (min-width: 768px) {
                    width: 40%;
                    flex-basis: 1;
                    padding: 0 2%;
                }
                p {
                    margin-bottom: 0;
                }
                @media screen and (min-width: 768px) {
                    margin-right: 20px;
                }
            }
            .slide-img {
                width: 100%;
                margin-bottom: 40px;
                @media screen and (min-width: 768px) {
                    width: 60%;
                    margin-bottom: 0px;
                }
                img {
                    margin: 0 auto;
                    width: 90%;
                }
            }
        }
    }
    .next-container {
        text-align: center;
        button {
            margin-top: 30px;
        }
    }
    .slick-dots {
        bottom: -35px;
    }
}
