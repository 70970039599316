.main {
    all: unset;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    border: 1px solid #dadada;
    border-radius: 50%;
    font-size: 25px;
    width: 48px;
    height: 48px;
    display: inline-grid;
    place-items: center;
    box-sizing: border-box;
}

.toolTips,
.options {
    display: none;
    z-index: 1;
    border-radius: 7px;
    font-size: 14px;
    padding: 10px;
    animation: fromUp 300ms ease-in-out forwards;
    text-align: center;
}

button:hover .toolTips {
    display: block;
    position: absolute;
    font-weight: bold;
    color: white;
    background-color: var(--darkGreyEgg);
    width: max-content;
    bottom: calc(100% + 16px);
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.toolTips::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-top: 10px solid var(--darkGreyEgg);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}

.options {
    display: block;
    padding: 7px;
    position: absolute;
    width: max-content;
    background-color: white;
    border: 1px solid #dadada;
    top: calc(100% + 16px);
}

.options::after {
    content: '';
    left: 50%;
    transform: translateX(-50%);
    border: 11px transparent solid;
    border-bottom: 11px white solid;
    position: absolute;
    top: -20px;
}

.options::before {
    content: '';
    left: 50%;
    transform: translateX(-50%);
    border: 11px transparent solid;
    border-bottom: 11px #dadada solid;
    position: absolute;
    top: -22px;
}

.optionItem {
    padding: 10px 15px;
    border-bottom: 1px solid #eaeaea;
}

.optionItem:last-child {
    border-bottom: 0;
}

.optionItem svg {
    margin-right: 10px;
}

@keyframes fromUp {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
