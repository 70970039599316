.user-action-stats {
    display: flex;
    justify-content: center;
    @media screen and (min-width: 768px) {
        margin-left: auto;
    }
    .stat-unit {
        display: flex;
        align-items: center;
        font-size: 2.5rem;
        font-weight: bold;
        padding: 0 10px;
        height: 30px;
        border-right: 1px solid #eaeaea;
        line-height: 1px;
        &:last-child {
            border-right: none;
        }
    }
}
