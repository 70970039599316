.responsive-menu {
    // @include degrade;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.09);
    height: 100vh;
    width: 80%;
    padding: 7%;
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    z-index: 10000;
    &.open {
        transform: translateX(0%);
        transition: all 0.3s ease;
        overflow-y: scroll;
    }
    .block-title {
        font-size: 1rem;
        color: darken($mediumGreyBg, 15%);
        font-weight: lighter;
        margin-top: 10px;
        display: block;
    }
    .user-info-container {
        border-bottom: 1px solid $borders;
        padding-bottom: 25px;
        .flex {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .avatar-block {
                margin-right: 15px;
            }
            .user-info-block {
                .name {
                    font-size: 1.25rem;
                    margin-bottom: 12px;
                }
                .btn-outline {
                    padding: 10px;
                }
            }
        }
        .date {
            font-size: 0.8rem;
            margin-right: 25px;
            text-transform: uppercase;
            font-weight: lighter;
            @media screen and (min-width: 768px) {
                display: block;
            }
        }
    }
    .role-switcher-container {
        border-bottom: 1px solid $borders;
        padding-top: 10px;
        padding-bottom: 20px;
        ul {
            list-style-type: none;
            margin-left: 0;
            padding-left: 0;
        }
        li {
            padding: 8px 0;
            box-sizing: border-box;
            &.active {
                &:before {
                    content: '';
                    display: inline-block;
                    height: 15px;
                    width: 15px;
                    background-color: $eggColor;
                    position: relative;
                    border-radius: 10px;
                    left: 0;
                    top: 2px;
                }
            }

            button,
            a {
                background: none;
                border: none;
                font-weight: bold;
                font-size: 1rem;
                letter-spacing: 0.08rem;
                text-transform: uppercase;
                cursor: pointer;
            }
            a {
                margin-left: 5px;
            }
        }
    }

    .other-links-container {
        border-bottom: 1px solid $borders;
        padding-top: 10px;
        padding-bottom: 20px;
        ul {
            list-style-type: none;
            margin-left: 0;
            padding-left: 0;
            li {
                padding: 8px 0;
                box-sizing: border-box;
                background: none;
                border: none;
                font-size: 1.1rem;
                cursor: pointer;
                button {
                    background: none;
                    border: none;
                    margin-left: 0;
                    padding-left: 0;
                }
            }
        }

        .active {
            background: $eggColor !important;
        }

        .lang-switch {
            text-align: center;
            display: flex;
            justify-content: center;
            padding: 10px 0;
            margin: 0 5px;
            margin-bottom: 10px;
            background: $lightGreyBg;
            border-radius: 5px;
            border: 1px solid $borders;
            &:hover {
                background-color: $eggColor;
                color: $darkGreyEgg;
            }
            button {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                border: none;
                background-color: transparent;
                cursor: pointer;
                .icon {
                    font-size: 1rem;
                }
                span {
                    font-weight: bold;
                    margin-left: 5px;
                }
            }
        }
    }
    .menu-items {
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        background: $lightGreyBg;
        border-radius: 5px;
        border: 1px solid $borders;
        font-weight: bold;
        margin-bottom: 10px;

        button {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            border: none;
            background-color: transparent;
            cursor: pointer;
            .icon {
                font-size: 1rem;
            }
            span {
                font-weight: bold;
                margin-left: 5px;
            }
        }
    }
    .logo-container {
        flex: 1;
        display: flex;
        align-items: flex-end;
    }
}
