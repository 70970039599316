.container {
    margin-right: 1.5em;
}

.actions {
    display: flex;
    align-items: center;
    gap: 1em;
}

.actionButton {
    border: 0;
    width: auto;
    height: auto;
    font-size: 23px;
    color: #dadada;
    background-color: unset;
}

.actions button:disabled {
    opacity: 1;
}

.actionButton:active {
    color: #d2d2d2;
}

.present:disabled {
    color: #7de780;
}

.absent:disabled {
    color: #ee8686;
}
