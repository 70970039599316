:root {
    --min-width: 170px;
    --min-height: 63px;
    --border: 1px solid #dadada;
}
.form {
    border: var(--border);
    border-radius: 15px;
    box-shadow: 0px 0px 8px #00000014;
    display: flex;
    min-height: var(--min-height);
    font-size: 18px;
    overflow: hidden;
    margin-bottom: 2em;
}

.options {
    line-height: var(--min-height);
    min-width: var(--min-width);
    padding: 0 20px;
    background-color: #f8f8f8;
    border-right: var(--border);
    cursor: pointer;
}
.options > span {
    margin-right: 15px;
}
.options > svg {
    height: 1em;
}

.moreOptions {
    color: gray;
    position: absolute;
    z-index: 1;
    background-color: inherit;
    border: var(--border);
    margin: 3px -20px;
    font-size: 16px;
    border-radius: 15px 0 15px 15px;
    padding: 10px 0;
    min-width: var(--min-width);
}

.moreOptions > div {
    line-height: normal;
    padding: 10px 20px;
}

.moreOptions .enable {
    cursor: pointer;
    color: #37474f;
}

.form input {
    border: 0;
    outline: 0;
    flex-grow: 2;
    margin-left: 20px;
    color: #37474f;
}

.form button {
    background: 0;
    outline: 0;
    border: 0;
    font-size: 2rem;
    color: #37474f;
    cursor: pointer;
    padding: 0 15px;
    margin-right: 15px;
}
.vFlip {
    transform: scaleY(-1);
}
