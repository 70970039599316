.openMeet {
    font-weight: bold;
    background-color: white !important;
    margin: 0;
}
.openMeet > svg {
    padding-right: 10px;
}

.zoomHybrid {
    background-color: white;
    padding: 12px;
    border-radius: 10px;
}
.zoomHybrid svg:first-child {
    font-size: 13px;
    margin-left: 0;
}

.zoomHybrid span {
    text-transform: capitalize;
    font-size: 12px;
}

.headerBtn {
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.8em;
    border: 0;
    border-radius: 8px !important;
    font-size: 12px !important;
    padding: 5px 20px !important;
    font-weight: bold;
}
