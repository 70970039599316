.container {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    width: fit-content;
    min-width: 480px;
}

.title {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;
}

.reportTitle {
    border-bottom: 1px solid #dedede;
    font-size: 18px;
    padding: 0 0 30px;
}

.datePicker {
    border-radius: 4px;
    border: 1px solid #dedede;
    color: inherit;
    height: 42px;
    outline: 0;
    padding: 0 1em;
}

.datePickerContainer {
    border-radius: 4px;
    border: 1px solid #dedede;
    margin: 40px 0;
    padding: 20px;
    width: max-content;
}

.datePickerTitle {
    font-size: 16px;
    padding: 0 50px 0 40px;
}

.buttonsContainer {
    display: flex;
    gap: 40px;
    justify-content: flex-end;
}
