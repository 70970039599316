.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 400px;
    min-height: 205px;
    cursor: pointer;
    padding: 40px;
}

.courseName {
    width: 100%;
    word-wrap: break-word;
    text-align: left;
}

.card p {
    padding: 0;
    margin: 0;
    font-size: 14px;
}
