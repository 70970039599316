.user-action-note {
    width: 100%;
    margin: 0;
    form {
        display: flex;
        align-items: center;
        @media screen and (min-width: 768px) {
            width: 80%;
            margin-left: auto;
        }
        .icon {
            font-size: 1.5rem;
            cursor: pointer;
        }
    }
}
