.under-head {
    background: transparent linear-gradient(99deg, #00aaff 0%, #ff7f7f 100%) 0% 0% no-repeat
        padding-box;
    width: 100%;
    position: sticky;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 35px 0 40px;
    z-index: 3;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    .div1 {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
    }
    .div2 {
        font-size: 15px;
        font-weight: bold;
        color: #073045;
        span {
            font-size: 14px;
            color: #ffffff;
            text-decoration: underline;
            margin-left: 15px;
            cursor: pointer;
        }
    }
    .div3 {
        font-size: 25px;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
    }
}
.wizard-pulse-main {
    z-index: 3;
    position: fixed;
    width: 920px;
    height: 660px;
    max-width: 90%;
    max-height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #bfc7d3;
    color: #e2dada;
    box-shadow: 0 0 15px 0px #cabfbf5e;
    border-radius: 15px;
    scrollbar-width: none;
    padding: 30px 50px 40px 50px;

    &.change-bg {
        background-color: #ffffff !important;
    }

    /*@media screen and (max-width: 768px) {
        position: fixed;
        max-width: 90%;
        background: rgb(32, 63, 29) !important;
        color: rgb(226, 218, 218);
        box-shadow: 0 0 15px 0px #d6d2d25e;
        top: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 1em;
        border-radius: 0;
        box-shadow: none;
        overflow-y: scroll;
        bottom: 0;
        height: 100vh;
        max-height: 100%;
    }*/
    .btn-wp-left {
        position: absolute;
        background-color: #9ca7b9;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        border: none;
        bottom: 40px;
        left: 50px;
        width: 150px;
        height: 45px;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
            background-color: #818da2;
        }
    }

    .btn-wp-right {
        position: absolute;
        background-color: #296efa;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        border: none;
        bottom: 40px;
        right: 50px;
        width: 150px;
        height: 45px;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
            background-color: #105df9;
        }
    }
    .btn-wp-back {
        position: absolute;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        color: #073045;
        border: 1px solid #eaeaea;
        bottom: 40px;
        right: 220px;
        width: 150px;
        height: 45px;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
            background-color: #f8f8f8;
        }
    }

    .btn-close {
        position: absolute;
        font-size: 28px;
        color: #43556f;
        border: none;
        top: 20px;
        right: 20px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        cursor: pointer;
    }

    .div-content {
        .p-margin {
            margin: 0;
        }
    }

    .new {
        color: #e56464;
        font-size: 14px;
        font-weight: 900;
        margin: 0;
    }
    .new-side {
        color: #acb6c6;
        font-size: 15px;
        margin-left: 15px;
    }
    .new-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 85%;
        z-index: -1;
    }
    .next-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);
        max-width: 100%;
        z-index: -1;
    }
    .next-img-2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);
        height: 70%;

        max-width: 100%;
        z-index: -1;
    }
    .under-new {
        color: #ffffff;
        font-size: 15px;
        font-weight: 700;
        margin: 30px 0 0 0;
    }
    .under-new-2 {
        color: #073045;
        font-size: 60px;
        font-weight: 900;
        margin-top: 15px;
        line-height: 60px;
        width: 330px;
    }
    .under-new-3 {
        color: #073045;
        position: absolute;
        bottom: 120px;
        right: 90px;
        width: 250px;
        font-size: 15px;
        font-weight: 700;
        line-height: 22px;
    }
    .under-next {
        color: #073045;
        font-size: 23px;
        font-weight: 900;
        margin-top: 25px;
        width: 350px;
    }
    .under-next-2 {
        color: #073045;
        position: absolute;
        bottom: 120px;
        right: 50px;
        width: 320px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin: 0;
    }

    .under-checkbox {
        position: absolute;
        display: flex;
        align-items: center;
        color: #073045;
        font-weight: bold;
        border: none;
        bottom: 40px;
        left: 50px;
        height: 45px;
        border-radius: 10px;
        &.other {
            width: 200px;
        }
        &.pt {
            width: 230px;
        }

        /* Hide the browser's default checkbox */
        .container input {
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            height: 14px;
            width: 14px;
            background-color: none;
            outline: 3px solid #073045;
            cursor: pointer;
            border-radius: 1px;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
            background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
            background-color: #1e5180;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
            left: 4px;
            top: 0px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .span-check {
            margin-top: 0px;
            margin-left: 22px;
            font-size: 12px;
        }
    }
}

.black-background {
    width: 100%;
    height: 120%;
    margin-top: -75px;
    position: fixed;
    background: rgba(7, 48, 69, 0.97);
    z-index: 2;
}
