button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    box-sizing: border-box;
}

button.egg {
    cursor: pointer;
    color: inherit;
    border: 1px solid inherit;
    border-radius: 5px;
    font-size: 1em;
    font-weight: bold;
    padding: 5px 25px;
}

button.outlined {
    color: $darkGreyEgg;
    font-weight: 700;
    background-color: inherit;
    padding: 1em;
    height: 36px;
    min-width: 160px;
    padding: 1em;
    border-radius: 8px;
    border: none;

    & > svg {
        margin: 0;
    }

    &.small {
        min-width: max-content;
    }
}

.btn-yellow {
    background: $eggColor;
    color: $darkGreyEgg;
    padding: 20px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: $border-radius-sm;
    border: 4px solid $eggColor;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        background: $mediumGreyBg;
        border: 4px solid $mediumGreyBg;
    }

    &.small {
        padding: 10px 30px;
        border-radius: 30px;
        font-size: 0.9rem;
        margin-left: 20px;

        &:disabled {
            opacity: 0.5;
        }
    }

    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 0.8;

        &:disabled {
            cursor: not-allowed;
            background: $mediumGreyBg;
            border: 4px solid $mediumGreyBg;
        }
    }
}

button.primary {
    background-color: #296efa;
    color: white;
    padding: 0.75rem 1.125rem;
    border: 1px solid #296efa;
    border-radius: 0.5rem;
    font-weight: 600;
    height: 40px;
    width: 100%;
}

button.accent {
    background-color: #ff647c;
    color: white;
    border: 0;
    padding: 0.75rem 1.125rem;
    border-radius: 0.5rem;
    font-weight: 600;
    height: 40px;
    width: 100%;
}

button.white {
    background-color: #fff;
    color: #003750;
    border: 1px solid #eaeaea;
    padding: 0.75rem 1.125rem;
    border-radius: 0.5rem;
    font-weight: 600;
    height: 40px;
    width: 100%;
}

button.primary:disabled,
button.accent:disabled,
button.white:disabled {
    color: #a0a0a0;
    background-color: #cfcfcf;
    pointer-events: none;
}

.btn-primary-color {
    color: white;
    background-color: $primaryColor;
    border-color: $primaryColor;

    &:hover {
        &:enabled {
            opacity: 1;
            background-color: $primaryColorHover;
            border-color: $primaryColorHover;
        }
    }
}

.btn-outline {
    @extend .btn-yellow;
    background: none;
    border: 4px solid $darkGreyEgg;
}

.btn-danger {
    color: white;
    background-color: $danger;
    border: 0;

    &:hover {
        opacity: 1;
        background-color: $dangerHover;
    }
}

.btn-outline-sm {
    @extend .btn-outline;
    padding: 5px 13px !important;
    border-radius: 20px;
    font-size: 0.8rem;
    border-width: 2px;
    letter-spacing: 0.03rem;
}

.btn-light-grey {
    background: $lightGreyBg;
    color: $darkGreyEgg;
    text-transform: uppercase;
    padding: 15px 0;
    letter-spacing: 0.05rem;
    font-weight: bold;
    border-radius: 20px;
    border: none;
    width: 100%;

    &:disabled {
        color: $mediumGreyBg;
        border: none;
    }
}

.action-link,
.action-link a {
    text-decoration: none;
    color: $darkGreyEgg;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    margin: 0 5px;
    text-transform: uppercase;
    user-select: none;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    span {
        font-weight: bold;
        letter-spacing: 0rem;
        font-size: 0.9rem;
    }

    .icon {
        margin: 0 10px;
        font-size: 1rem;
    }
}

.give-egg-button {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1em 1.5em;
    color: #37474f;
    font-size: 0.9rem;
    font-weight: bold;
    border: 1px solid #dadada;
    border-radius: 100px;
    cursor: pointer;
    text-transform: uppercase;
    min-height: 50px;

    &.voted-false {
        background: rgb(239, 239, 239);
        color: $darkGreyEgg;
    }

    &.voted-true,
    &:hover {
        background: $darkGreyEgg;
        color: white;
        border: none;

        .central-logo {
            color: $eggColor;
        }
    }

    .central-logo {
        font-size: 1.8rem;
        height: 20px;
        color: $eggColor;
    }
}

.underline-link {
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0.01rem;
    text-transform: uppercase;

    &:hover {
        text-decoration: underline;
    }
}

button.link,
button.icon {
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    padding: 0.44em 1em;
    min-width: 140px;
    border-radius: 8px;

    color: $white;
    background-color: $darkGreyEgg;
    border-color: $darkGreyEgg;
    border: 2px solid $darkGreyEgg;

    &.icon {
        color: $darkGreyEgg;
        background-color: transparent;

        &:hover {
            color: $primaryColorHover;
        }
    }

    &.icon,
    &.icon:hover {
        border: 0;
        padding: 0 0.2em;
        min-width: 2em;

        > svg {
            margin: 0;
        }
    }

    & + button {
        margin-left: 2em;
    }

    & svg {
        margin: 0 0.4em;
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.link {
        color: $darkGreyEgg;
        background-color: transparent;

        &:hover {
            opacity: 0.8;
        }
    }

    &.link {
        all: unset;
        cursor: pointer;
        text-decoration: underline;
    }
}
