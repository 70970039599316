.toast-rotation-container {
    position: fixed;
    z-index: 2;
    bottom: 20px;
    right: 20px;
    width: 437px;
    height: 70px;
    background: transparent linear-gradient(99deg, #ff647c 0%, var(---00a3ff) 100%) 0% 0% no-repeat
        padding-box;
    background: transparent linear-gradient(99deg, #ff647c 0%, #00a3ff 100%) 0% 0% no-repeat
        padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.animationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #ffffff;
    height: 70%;
    width: 70px;
}
.animacionCarga {
    animation-name: moveDot;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

@keyframes moveDot {
    0% {
        transform: translatey(0);
    }

    25% {
        transform: translatey(-100%);
    }
    50% {
        transform: translatey(25%);
    }
    75% {
        transform: translatey(-50%);
    }
    100% {
        transform: translatey(0);
    }
}
