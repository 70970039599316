:root {
    --monitor-card-rounded: 10px;
    --monitor-card-background: white;
    --monitor-card-text-light: #666d78;

    --color-success: #15c1b0ff;
    --color-success-light: #15c1b026;
    --color-info: #00a3ff;
    --color-info-light: #00aaff26;
    --color-danger: #ff6673ff;
    --color-danger-light: #ff667326;
    --color-exclamation: #ffb100;
    --color-egg: #ffcd00;
    --color-egg-light: #ffcd0026;
}
