@import url('./styles.css');

.card {
    position: relative;
    border-radius: var(--monitor-card-rounded);
    background: var(--monitor-card-background);
    padding: 30px;
    display: grid;
    align-content: space-between;
    gap: 30px;
    font-weight: 500;
    text-align: center;
}

.data {
    font-size: 90px;
    line-height: 108px;
    font-weight: 900;
    text-align: center;
    letter-spacing: -1.8px;
}
.desc {
    border-bottom: 1px solid #dedede;
    padding-bottom: 2em;
}
.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 17px;
    text-align: center;
    gap: 1em;
}
.footer > div {
    display: flex;
    align-items: center;
}
.footerIcon {
    margin-right: 10px;
}

.footerData {
    font-weight: 700;
}
