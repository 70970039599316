.notifications {
   .notification-center-page {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .mark-all-read {
        margin-left: auto;
        margin-right: 15px;
    }
}
}

.no-notif-msg {
    font-weight: bold;
    text-align: center;
}


.back-btn {
    margin-left: auto;
    margin-top: 15px;
    @media screen and (min-width: 768px) {
        margin-left: 10%;
    }
}
