.login-pages {
    .onboarding-form {
        .code-input {
            display: flex;
            flex-direction: column;
            //padding: 10px;
            .pin-code {
                text-align: center;
                display: flex;
                flex-direction: row;
                margin: 4px 0;
                //align-self: center;
                @media screen and (min-width: 768px) {
                    margin: 10px 20px;
                }
                input {
                    color: $darkGreyEgg;
                    text-align: center;
                    margin: 3px;
                    padding: 7px;
                    font-size: 2rem;
                    @media screen and (min-width: 768px) {
                        font-size: 2.5rem;
                        padding: 20px 0;
                        margin: 5px;
                    }
                    display: flex;
                    flex: 1;
                    width: 0;
                    min-width: 0;
                    font-weight: 400;
                    border-radius: $border-radius-sm;
                    border: 1px solid $mediumGreyBg;
                    background-color: $lightGreyBg;
                }
                flex: 4;
            }
            .form-input {
                text-align: center;
                flex: 4;
                margin: 10px;
                font-size: 2rem;
                font-weight: 400;
                letter-spacing: 2px;
                &::placeholder {
                    font-size: 1.5rem;
                }
            }
            .btn-yellow {
                flex: 1;
                margin: 15px;
                width: 50%;
                align-self: center;
                text-transform: uppercase;
                font-size: 1rem;
                //padding: 10px;
            }
        }
        .action-buttons {
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}
