.meeting-configuration {
    padding: 0 95px;
    .zoom-config {
        @include sombra;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 15px;
    }
    .zoom-config {
        text-align: center;
        justify-content: center;
        align-items: center;
        // height: 300px;
        padding: 80px;
        margin: 30px 0 0;
        position: relative;
        .icon {
            color: #00a3ff;
            font-size: 34px;
            margin-bottom: 10px;
        }
        .title {
            color: #003750;
            font-size: 17px;
            font-weight: bold;
            letter-spacing: 0px;
            line-height: 23px;
            margin: 10px 0px 10px;
        }
        .global-text {
            color: #606060;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 22px;
            margin-bottom: 15px;
        }
        button {
            background: #003750;
            border: 1px solid #003750;
            border-radius: 10px;
            color: #ffffff;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0px;
            line-height: 17px;
            padding: 16px 32px;

            &:disabled {
                background: #eaeaea;
                border: 1px solid #eaeaea;
                color: #c7c7c7;
            }
        }
        .selector {
            display: flex;
            justify-content: center;
            align-items: center;
            select,
            input {
                border-radius: 10px;
                border: 1px solid #eaeaea;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0px;
                line-height: 17px;
                margin-top: 15px;
                min-height: 49px;
                padding: 16px 32px;
                @media screen and (min-width: 768px) {
                    margin-top: 0;
                    margin-right: 20px;
                }
            }
        }
        .initial-table-config {
            margin: 10px;
            width: unset;
            .label {
                margin-right: 10px;
            }
            input {
                width: 100px;
                border: none;
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            .button-container {
                margin: 0px 40px;
                flex: 3;
            }
            .initial-table-config {
                flex: 2;
                display: flex;
                align-self: flex-end;
                flex-direction: column;
                .input-container,
                .checkbox-container {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    input {
                        flex: 1;
                        padding: 20px;
                        width: 80px;
                        height: 50px;
                        border-radius: 15px;
                    }
                    input[type='checkbox'] {
                        width: 50px;
                        height: 50px;
                        border-radius: 15px;
                    }
                    .label {
                        margin: 0px 20px;
                        flex: 2;
                        align-self: center;
                    }
                }
            }
        }
        .action-buttons {
            .btn-yellow {
                margin: 10px 10px;
            }
        }
        .btn-yellow {
            // margin:15px 0 0;
            width: 200px;
            height: 60px;
            margin-top: 15px;
            padding: 0 !important;
            @media screen and (min-width: 768px) {
                margin-top: 0;
            }
        }
    }

    .classroom-links-list {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        .section {
            display: flex;
            flex-direction: column;
            margin: 10px 0px 16px;
            .title {
                color: #073045;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0.65px;
                line-height: 16px;
                margin-bottom: 24px;
                text-transform: uppercase;
                @media screen and (min-width: 768px) {
                    margin-bottom: 20px;
                }
            }
            .link-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 10px 0;
                @media screen and (min-width: 768px) {
                    flex-direction: row;
                    align-items: center;
                    // margin: 10px;
                }
                .label {
                    color: #073045;
                    flex: 1;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0px;
                    line-height: 22px;
                    padding: 10px;
                    @media screen and (min-width: 768px) {
                        padding: 0px;
                    }
                }
                .input {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    flex: 3;
                    .form-input {
                        background: #ffffff;
                        border-radius: 10px;
                        border: 1px solid #eaeaea;
                        color: #1f394c;
                        font-size: 17px;
                        font-weight: 600;
                        letter-spacing: 0px;
                        line-height: 23px;
                        padding: 10px;

                        &:placeholder-shown {
                            background: #f2f2f2;
                            color: #c7c7c7;
                        }
                    }
                }
            }
        }
        .action-buttons {
            align-self: flex-end;
        }
    }

    .service-switch-block {
        display: flex;
        flex-wrap: wrap;
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
        .service-item {
            flex-basis: calc(50% - 20px);
            @media screen and (min-width: 768px) {
                flex: 1 1 0;
                padding: 10px 20px;
            }
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 10px;
            // opacity:.3;
            // border:1px solid $borders;
            border-radius: 15px;
            min-height: 180px;
            position: relative;
            &.inactive {
                box-shadow: none;
            }
            &.active {
                // opacity:1;
                border: 2px solid white;
            }
            .power {
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 1.5rem;
                &.active {
                    color: #00a3ff;
                }
            }
            cursor: pointer;
            img {
                width: 70px;
                margin-bottom: 7px;
            }
            span {
                font-size: 1.2rem;
                font-weight: bold;
                margin-top: 0.5rem;
            }
            @media screen and (min-width: 768px) {
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .mode-switch-block {
        background-color: darken(white, 5%);
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
        .mode-button {
            cursor: pointer;
            flex-basis: 50%;
            border-radius: 15px;
            border-radius: 15px;
            text-align: center;
            background-color: transparent;
            padding: 20px 40px;
            @media screen and (min-width: 768px) {
                padding: 20px 80px;
            }
            .title {
                font-size: 1.8rem;
                font-weight: bold;
            }
            &.active {
                @include sombra;
                background-color: white;
                .title {
                    color: $eggColor;
                }
            }
        }
    }

    .actions-grey-block {
        background-color: $lightGreyBg;
    }
}
