:root {
    --border: 1px solid #ececec;
}

.section {
    background-color: #f7f7f7;
    border: var(--border);
    border-radius: 10px;
    padding: 34px;
    margin: 1em 0;
}

.mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2em;
    margin-bottom: 34px;
}
.btnClearResult {
    border: 0;
    background: 0;
    outline: 0;
    cursor: pointer;
    color: inherit;
}

.results {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #ececec;
    padding: 2em;
}
