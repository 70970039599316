.link-block-msg {
    @extend .egg-card;
    flex: 1 1 0;
    .content {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 20px;
        @media screen and (min-width: 768px) {
            text-align: left;
            flex-direction: row;
        }
        .icon-container {
            flex-basis: 30%;
            margin-bottom: 20px;
            @media screen and (min-width: 768px) {
                text-align: center;
                flex-direction: row;
            }
            .icon {
                font-size: 7rem;
                color: $eggColor;
            }
        }
        .texts {
            flex-basis: 70%;
            padding: 0 30px;
            // background-color: green;
            .title {
                display: block;
                margin-bottom: 10px;
                font-size: 1.3rem;
                line-height: 1.6rem;
                font-weight: bold;
            }
            .global-text {
                margin: 0;
            }
        }
    }
    .link {
        font-size: 1.3rem;
        font-weight: bold;
        width: 100%;
        margin-left: auto;
        text-align: center;
        margin-top: 20px;
        @media screen and (min-width: 768px) {
            text-align: right;
        }
        .icon {
            color: $eggColor;
            margin-left: 3px;
            font-size: 1.1rem;
        }
    }
    &#link-csv {
        margin-bottom: 10px;
        @media screen and (min-width: 768px) {
            margin-bottom: 0;
            margin-right: 10px;
        }
    }
    &#link-metodologia {
        margin-top: 10px;
        @media screen and (min-width: 768px) {
            margin-top: 0;
            margin-left: 10px;
        }
    }
}
