.student-heading-container {
    .navigation {
        display: flex;
        align-items: center;
        justify-content: right;
        margin: 2em auto;
        .dropdown {
            width: 160px;
            min-width: 160px;
            z-index: 2;
            & > button {
                border-style: none;
                background: inherit;
                display: flex;
                gap: 0.5em;
                align-items: center;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 0rem;
                font-size: 0.9rem;
            }
            &.show {
                display: block;
            }
            ul {
                margin: 0 5px;
                padding: 0;
                list-style: none;
                &.langs-visible {
                    li {
                        button {
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                }
                li {
                    background: $lightGreyBg;
                    border: 1px solid $borders;
                    border-radius: 3px;
                    margin: 10px 0px;
                    padding: 10px;
                    font-weight: bold;
                    text-align: center;
                    &:hover {
                        background-color: $eggColor;
                        color: $darkGreyEgg;
                    }
                    a {
                        width: 100%;
                        display: block;
                    }
                    button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $darkGreyEgg;
                        background: none;
                        border: none;
                        font-weight: bold;
                        cursor: pointer;
                        width: 100%;
                    }
                    .icon {
                        color: $mediumGreyBg;
                    }
                    &.active {
                        background-color: $eggColor;
                        .icon {
                            color: $darkGreyEgg;
                        }
                    }
                }
            }
        }
    }
    .student-data {
        // @include egg-general-block;
        margin-bottom: 2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        .student-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            .rest-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-left: 0px;
                @media screen and (min-width: 768px) {
                    margin-left: 50px;
                }
                h2 {
                    font-size: 3em;
                    letter-spacing: 0.05rem;
                    padding: 10px;
                    text-align: center;
                }
                .email {
                    padding: 0 10px;
                }
                .status-msg-block {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-top: 15px;
                    height: 50px;

                    @media screen and (min-width: 768px) {
                        justify-content: start;
                    }
                    .max-group {
                        height: 100%;
                    }

                    .max-group-btn {
                        height: 100%;
                        width: 100%;
                    }

                    .status-btn {
                        padding: 10px;
                        display: block;
                        text-align: left;
                        width: 100%;
                        border-radius: 10px;
                        border: 1px solid rgba(20, 20, 20, 0.2);
                        &:disabled {
                            background-color: white;
                            border: none;
                        }
                    }
                    .status-msg {
                        padding: 10px;
                        display: block;
                        text-align: left;

                        &:disabled {
                            background-color: white;
                            border: none;
                        }
                    }
                    .edit-btn {
                        background: none;
                        border: none;
                        // margin-left: -40px;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
                .stats-block {
                    display: flex;
                    justify-content: center;
                    .stat-item {
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        padding: 10px;
                        @media screen and (min-width: 768px) {
                            flex-direction: row;
                            align-items: center;
                            text-align: left;
                        }
                        .number {
                            font-weight: bold;
                            font-size: 2.7rem;
                            @media screen and (min-width: 768px) {
                                font-size: 2.7rem;
                                margin-right: 10px;
                            }
                        }
                        .label {
                            font-weight: bold;
                            font-size: 0.9rem;
                            @media screen and (min-width: 768px) {
                                font-size: 1rem;
                            }
                        }
                    }
                }
                .qualify-block {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    .stars-block {
                        display: flex;
                        flex-direction: row;
                        flex: 1;
                        justify-content: flex-start;
                        padding: 0 10px;
                        @media (min-width: 768px) {
                            flex: 0;
                            justify-content: flex-start;
                            .slash {
                                padding-left: 0px;
                            }
                        }
                        .star-icon {
                            font-size: 1.5rem;
                            margin: 0 5px;
                            @media screen and (min-width: 768px) {
                                margin: 0 10px;
                            }
                        }
                    }
                    .action-btn {
                        .btn-qualify {
                            @extend .btn-yellow;
                            padding: 10px 30px;
                            border-radius: 30px;
                            font-size: 0.9rem;
                            margin-left: 20px;
                            &:disabled {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
            .avatar-container {
                width: 180px;
                height: 180px;
                .main {
                    width: 180px;
                    height: 180px;
                }
            }
        }
    }
    @media screen and (min-width: 768px) {
        & > .student-data {
            flex-direction: row;
            .student-info {
                flex-direction: row;
                align-items: left;
            }
        }
    }
}

.max-block {
    width: 100%;
    @media screen and (min-width: 768px) {
        width: 550px;
    }
}

.min-block {
    width: 100%;
}

.max-label-group {
    display: flex;
    justify-content: center;
}
