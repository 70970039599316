.dropdown {
    position: absolute;
    background-color: white;
    margin-top: 1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid #f4f4f4;
    box-shadow: 0px 0px 6px #00000029;
    width: 100%;
    min-width: max-content;
    top: calc(100%);
    right: 0;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.dropdownItem:last-child {
    border: none;
}

.dropdownItem {
    padding: 7px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
}
