.user-action-reassign {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    & > input {
        max-width: 100px;
        padding: 5px !important;
        padding-left: 20px !important;
        text-align: center;
    }
    @media screen and (min-width: 768px) {
        margin-left: auto;
    }
    .btn-reassign {
        @extend .btn-yellow;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 0.9rem;
        margin-left: 10px;
        height: 42px;
        color: white;
        background-color: $darkGreyEgg;
        border: none !important;
        border: 1px solid $darkGreyEgg !important;
        &:hover {
            opacity: 1;
            background: $darkGreyEggHover;
            border: 1px solid $darkGreyEggHover !important;
        }
        &:disabled {
            opacity: 1;
            background-color: $lightGreyBg;
            color: $mediumGreyBg;
            border: 1px solid $borders !important;
        }
    }

    .btn-assign {
        @extend .btn-reassign;
        background-color: $darkGreyEgg;
        border-color: $darkGreyEgg !important;

        &:hover {
            background-color: $primaryColorHover;
            border-color: $primaryColorHover !important;
        }

        &:disabled {
            color: #999999;
            background-color: #eaeaea;
        }
    }
}
