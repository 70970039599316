.team-detail-container {
    padding: 14px 0px !important;
    .header {
        background: $eggColor;
        background-size: 140%;
        background-position: -50% 50%;
        background-repeat: no-repeat;
        border-radius: $border-radius-md;
        padding: 40px;
        @media screen and (min-width: 768px) {
            background: $eggColor url('../../../assets/images/team_detail_bg.png');
            padding: 50px 80px;
            background-size: 45%;
            background-position: 100% center;
            background-repeat: no-repeat;
        }
        .hq-name {
            font-size: 1.8rem;
            @media screen and (min-width: 768px) {
                font-size: 2.28rem;
            }
            font-weight: bold;
            color: white;
        }
        .team {
            width: 100%;
            @media screen and (min-width: 768px) {
                width: 60%;
            }
            h1 {
                margin: 0;
                margin-top: 10px;
                font-size: 3rem;
                padding-bottom: 15px;
                @media screen and (min-width: 768px) {
                    font-size: 5rem;
                    line-height: 5rem;
                }
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .goal {
            margin-top: 14px;
            font-size: 1.2rem;
            margin-bottom: 30px;
            width: 100%;
            @media screen and (min-width: 768px) {
                width: 60%;
            }
        }

        .actions-block {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
            .action-link {
                margin-left: -15px;
                margin-bottom: 10px;
                .icon {
                    width: 25px;
                }
                @media screen and (min-width: 768px) {
                    margin-left: 0px;
                }
                &:first-child {
                    @media screen and (min-width: 768px) {
                        // margin-bottom: 0px;
                        margin-left: -15px;
                    }
                }
                &:hover {
                    .icon {
                        color: white;
                    }
                }
            }
        }
    }

    .unassigned-students-block {
        border-radius: 10px;
        margin-bottom: 24px;
        background-color: #f0f0f0;
        padding: 10px 16px;
        span {
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 20px;
            @media screen and (min-width: 768px) {
                margin-bottom: 0;
            }
        }
        .btn {
            margin: 0 auto;
        }
        @media screen and (min-width: 768px) {
            .btn {
                margin-left: auto;
                margin-right: 0;
            }
        }
        .actions-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            justify-self: flex-end;
            margin-left: auto;
            .btn {
                flex: 1;
                width: 100%;
            }
            .discrete-btn {
                border: none;
                background-color: transparent;
                color: inherit;
                cursor: pointer;
                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }

    .empty-block {
        @extend .egg-card;
        text-align: center;
        margin: 20px 0;
        padding: 40px;
        @media screen and (min-width: 768px) {
            padding: 100px;
        }
        h3 {
            margin-bottom: 10px;
            text-align: center !important;
        }
        .icon {
            color: $eggColor;
            font-size: 6rem;
            margin-bottom: 20px;
        }
        p {
            margin: 0;
            margin-bottom: 20px;
        }
        #share-url {
            font-size: 1.8rem;
            color: $darkGreyEgg;
            text-align: center;
            font-weight: 300;
            width: 100%;
            @media screen and (min-width: 768px) {
                width: 50%;
            }
        }
    }

    .messages-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
    }

    .block-stats {
        h3 {
            margin: 30px 0;
            span {
                font-weight: lighter;
            }
        }
    }

    .tables-block-container {
        .tables-block {
            @include egg_general_block;
        }

        .is-placeholder {
            opacity: 0.25;
            pointer-events: none;
            visibility: hidden;
            @media screen and (min-width: 768px) {
                visibility: visible;
            }
        }
    }

    .facilitadores-block {
        margin: 20px 0;
        padding: 60px 10px;
        border-radius: $border-radius-md;
        background-color: $darkGreyEgg;
        color: white !important;
        @media screen and (min-width: 768px) {
            padding: 60px;
        }
        &.placeholder {
            opacity: 0.3;
        }
        .heading {
            h3 {
                text-align: center;
            }
            @media screen and (min-width: 768px) {
                h3 {
                    text-align: left;
                }
            }
        }
        .content {
            padding: 40px 0 0;
            .facilitador-item {
                display: flex;
                text-align: center;
                flex-direction: column;
                align-items: center;
                // justify-content: center;
                &.is-placeholder {
                    // background-color: red;
                    opacity: 0.1;
                    pointer-events: none;
                    visibility: hidden;
                    @media screen and (min-width: 768px) {
                        visibility: visible;
                    }
                }
                .avatar-egg {
                    margin: 0 auto;
                    border: none;
                }
                .name-label {
                    font-size: 1.2rem;
                    margin-top: 15px;
                    color: white;
                }
                .name-table {
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 0.01rem;
                    margin-top: 4px;
                    color: white;
                }
            }
        }
    }
}
