h1 {
    font-size: 5rem;
    font-weight: bold;
    letter-spacing: -0.15rem;
    margin: 10px 0;
}

h2 {
    margin: 0;
    font-size: 3.4rem;
    font-weight: bold;
    letter-spacing: -0.15rem;
    text-align: center;
    @media screen and (min-width: 768px) {
        text-align: left;
    }
}

h3 {
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.01rem;
    margin: 0;
    text-align: center;
    @media screen and (min-width: 768px) {
        text-align: left;
    }
}

h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

a {
    text-decoration: none;
    color: $darkGreyEgg;
    &:hover {
        opacity: 0.8;
    }
}

.global-text {
    font-size: 1.2rem;
    color: $paragraphColor;
    line-height: 1.8rem;
}

.light-span-md {
    font-size: 1.5rem;
    font-weight: lighter;
}

.route-title {
    font-size: 0.93rem;
    letter-spacing: 0.01rem;
    text-transform: capitalize;
    font-weight: bold;
}

.text-lighter {
    font-weight: 300;
}

.text-strong {
    font-weight: bold;
}

// Descripciones superiores a bloques - instrucciones - detalles - label
.block-label {
    display: block;
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 5px;
}

.block-desc {
    display: block;
    font-size: 1rem;
    font-weight: normal;
    opacity: 0.6;
}

.text-centered {
    text-align: center;
}

.text-white {
    color: white;
}

.success {
    color: #15c0af;
}

.danger {
    color: #ff647c;
}
.yellowEgg {
    color: #ffcd00;
}

.checkBoxField-container {
    display: grid;
    grid-template-columns: 1fr 8fr 2fr;
}
.checkBoxField-description {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.checkBoxField-title {
    color: #073045;
    font-size: 15px;
    font-weight: medium;
    letter-spacing: 0px;
    line-height: 19px;
    text-align: left;
}
.checkBoxField-label {
    color: #606060;
    font-size: 14px;
    font-weight: medium;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: left;
}
.checkBoxField-icon {
    align-items: center;
    display: flex;
    justify-content: center;
}
.checkBoxField-toggle {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
