.fill-wizard-page {
    background: none;
    min-height: auto;
    margin: auto 0;
    .container {
        margin: 10px;
        min-height: 400px;
        padding: 60px 20px;
        box-shadow: none;
        @media screen and (min-width: 768px) {
            padding: 60px;
            width: 60%;
            min-height: 400px;
        }
        &.user-info {
            form {
                .block-row {
                    margin-bottom: 30px;
                    h4 {
                        margin-bottom: 30px;
                    }
                    .flex-row {
                        width: 100%;
                        display: flex;
                        .form-block {
                            // background-color: red;
                            margin-bottom: 20px;
                            @media screen and (min-width: 768px) {
                                width: 50%;
                                &.w-100 {
                                    width: 100%;
                                }
                                &:first-child {
                                    margin-right: 10px;
                                    &.w-100 {
                                        margin-right: 0;
                                    }
                                }
                                &:last-child {
                                    margin-left: 10px;
                                    &.w-100 {
                                        margin-left: 0;
                                    }
                                }
                            }
                            .block-desc {
                                margin-bottom: 8px;
                            }
                            .input-error {
                                margin-top: 5px;
                                display: block;
                            }
                        }
                    }
                }
                .submit-block {
                    display: flex;
                    justify-content: flex-end;
                    button {
                        margin-left: auto;
                    }
                }
            }
        }
        &.avatar-info {
            .stage-select {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .heading {
                    text-align: center;
                    h4 {
                        margin-top: 20px;
                    }
                    p {
                        width: 60%;
                        margin: 15px auto;
                        // text-align: center;
                    }
                }
            }
            .stage-stream {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                button {
                    margin-top: 30px;
                }
            }
            .action-buttons {
                display: flex;
                margin-bottom: 20px;
                text-align: center;
                button {
                    margin: 5px;
                    @media screen and (min-width: 768px) {
                        min-width: 180px;
                    }
                }
            }
        }
    }
}
