.loading {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #073045;
}
.container {
    display: grid;
    gap: 2rem;
}
.updateData {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.btnRefresh {
    align-items: center;
    color: #003750;
    background: #ffffff;
    border: 2px solid #003750;
    border-radius: 8px;
    display: flex;
    font-size: 15px;
    min-width: unset;
    padding: 10px 15px;
    position: relative;
}

.btnRefresh:hover {
    background-color: #eaeaea;
}

.btnRefreshText {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
}

/* Live */

.liveContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.rowContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.786rem; /* 25px */
}
.rowContainer > section {
    width: 361px;
    height: 330px;
}

.bold {
    font-weight: bold;
}

.boldRed {
    color: var(--color-danger);
    font-weight: bold;
}

.link {
    text-align: left;
    color: #003750;
    opacity: 1 !important;
    font-weight: 500;
    background-color: transparent;
    border: none;
    padding: 0;
    display: initial;
}

.titleLink {
    color: #003750;
    background-color: transparent;
    border: none;
    font-weight: bold;
    padding: 0;
}

.link:disabled,
.titleLink:disabled {
    opacity: 1;
}

.link:not(:disabled):hover,
.titleLink:not(:disabled):hover {
    text-decoration: underline;
}

/* Modal */

.modal {
    min-width: 700px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.closeIcon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    font-size: x-large;
}

/* Table */
.tableContainer {
    width: 100%;
    max-height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
}

.tableHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.attendanceTable {
    min-width: 500px;
    width: 100%;
    border-collapse: collapse;
}

.attendanceTable thead {
    background-color: #f4f4f4;
}

.attendanceTable th,
.attendanceTable td {
    text-align: left;
    padding: 1.5rem 0.5rem;
    border-bottom: 1px solid #ddd;
}

.attendanceTable th {
    font-weight: bold;
}

.attendanceBody {
    max-height: 500px;
}
.copyIcon {
    cursor: pointer;
    margin-left: 5px;
    transition: color 0.3s ease;
}

.copyIcon:hover {
    color: #0056b3;
}

.itemCenter {
    text-align: center !important;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .modal {
        min-width: 300px;
    }
    .attendanceTable {
        font-size: 13px;
    }
}

.talkingQualityRow {
    border-bottom: 0.5px solid var(--separadorLineas);
    cursor: pointer;
}

.talkingQualityRow:hover {
    background-color: var(--lightGrey);
}

.talkingQualityItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem 1.5rem;
}

.talkingQualityRow:last-child,
.attendanceRow:last-child {
    border-bottom: none;
}

.itemCenter {
    justify-self: center;
    text-align: center;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.actions > .title {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.actions > button {
    width: max-content;
}

.buttonTitle {
    font-size: 15px;
    font-weight: bold;
}
