.enroll-page-container {
    flex: 1;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    img {
        width: 278px;
        max-width: 80%;
        margin-bottom: 30px;
    }
    span.hello-badge {
        background-color: $lightGreyBg;
        padding: 10px 20px;
        display: block;
        font-weight: bold;
        width: auto;
        margin-bottom: 20px;
    }
    h3 {
        text-align: center;
        margin-bottom: 20px;
    }
    @media screen and (min-width: 768px) {
        width: 40%;
    }
    button {
        width: 100%;
        margin: 5px;
        text-align: center;
    }
    .action-link-enroll {
        text-align: center;
        background: none;
        border: none;
        .icon {
            color: $eggColor;
            margin-left: 6px;
            margin-bottom: -2px;
        }
    }
}
